import {
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AllgemeinDraftDTO,
  Anwendungsart,
  Arzneiform,
  BehaelterGroesseDraftDTO,
  BerechtigungAntragDTO,
  CloneFormelDTO,
  CopyFormelDTO,
  FileDTO,
  FormelApiService,
  FormelDraftApiService,
  FormelDraftDTO,
  FormelDTO,
  FormelHistoryEntryDTO,
  FormelRisikoParameterDTO,
  Herstellungsprozess,
  KompenenteDraftDTO,
  KomponentApiService,
  KomponentSuggestionDTO,
  LagerungDraftDTO,
  LohnherstellerDraftDTO,
  Mengenverhaeltnis,
  Produktionsmenge,
  UpdateFormelStateDTO,
  Wirkstoffrisiko,
  ZusammensetzungDraftDTO,
} from '../../../backend';
import { ActivatedRoute, Router } from '@angular/router';
import { List } from 'immutable';
import { FormelDraftMapperService } from '../../../services/mapper/formel-draft-mapper.service';
import { FormelMapperService } from '../../../services/mapper/formel-mapper.service';
import { PathAsString } from '../../../data/enhanced-route';
import { routes } from '../../../app-routing.module';
import { TranslateService } from '@ngx-translate/core';
import { BerechtigungService } from '../../../services/berechtigung.service';
import { first, Observable } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BewInputComponent } from '../../../../bew/components/bew-input/bew-input.component';
import { BewSelectComponent } from '../../../../bew/components/bew-select/bew-select.component';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import LagerungshinweiseEnum = LagerungDraftDTO.LagerungshinweiseEnum;
import PraeparatArtEnum = AllgemeinDraftDTO.PraeparatArtEnum;
import DarreichungsformEnum = AllgemeinDraftDTO.DarreichungsformEnum;
import ChargengroesseEinheitEnum = ZusammensetzungDraftDTO.ChargengroesseEinheitEnum;
import StateEnum = FormelHistoryEntryDTO.StateEnum;
import RolleEnum = BerechtigungAntragDTO.RolleEnum;

@Component({
  selector: 'app-formel-create',
  templateUrl: './formel-detail.component.html',
  styleUrl: './formel-detail.component.scss',
})
export class FormelDetailComponent {
  @ViewChild('allgemeinPreaparatName')
  allgemeinPreaparatName!: BewInputComponent;
  @ViewChild('allgemeinPreaparatArt')
  allgemeinPreaparatArt!: BewSelectComponent;
  @ViewChild('allgemeinIndikation') allgemeinIndikation!: BewInputComponent;
  @ViewChild('allgemeinDosierungsvorschriften')
  allgemeinDosierungsvorschriften!: BewInputComponent;
  @ViewChild('allgemeinDarreichungsform')
  allgemeinDarreichungsform!: BewSelectComponent;

  @ViewChild('allgemeinLohnherstellerBetriebsname')
  allgemeinLohnherstellerBetriebsname!: BewInputComponent;

  @ViewChildren('allgemeinItem', { read: BewInputComponent })
  allgemeinItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('allgemeinItem', { read: BewSelectComponent })
  allgemeinItemSelects!: QueryList<BewSelectComponent>;

  @ViewChildren('allgemeinLohnherstellerItem', { read: BewInputComponent })
  allgemeinLohnherstellerItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('allgemeinLohnherstellerItem', { read: BewSelectComponent })
  allgemeinLohnherstellerItemSelects!: QueryList<BewSelectComponent>;

  @ViewChildren('zusammensetzungItem', { read: BewInputComponent })
  zusammensetzungItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('zusammensetzungItem', { read: BewSelectComponent })
  zusammensetzungItemSelects!: QueryList<BewSelectComponent>;

  @ViewChildren('lagerungItem', { read: BewInputComponent })
  lagerungItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('lagerungItem', { read: BewSelectComponent })
  lagerungItemSelects!: QueryList<BewSelectComponent>;

  @ViewChildren('verpackungItem', { read: BewInputComponent })
  verpackungItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('verpackungItem', { read: BewSelectComponent })
  verpackungItemSelects!: QueryList<BewSelectComponent>;

  @ViewChild('zusammensetzungKomponentBezeichnung')
  zusammensetzungKomponentBezeichnung!: BewInputComponent;
  @ViewChild('zusammensetzungKomponentBemerkung')
  zusammensetzungKomponentBemerkung!: BewInputComponent;
  @ViewChildren('zusammensetzungKomponentItem', { read: BewInputComponent })
  zusammensetzungKomponentItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('zusammensetzungKomponentItem', { read: BewSelectComponent })
  zusammensetzungKomponentItemSelects!: QueryList<BewSelectComponent>;

  @ViewChild('zusammensetzungKomponentBemerkungEdit')
  zusammensetzungKomponentBemerkungEdit!: BewInputComponent;
  @ViewChildren('zusammensetzungKomponentItemEdit', { read: BewInputComponent })
  zusammensetzungKomponentItemInputsEdit!: QueryList<BewInputComponent>;
  @ViewChildren('zusammensetzungKomponentItemEdit', {
    read: BewSelectComponent,
  })
  zusammensetzungKomponentItemSelectsEdit!: QueryList<BewSelectComponent>;

  @ViewChildren('verpackungBehaeltergroesseItem', { read: BewInputComponent })
  verpackungBehaeltergroesseItemInputs!: QueryList<BewInputComponent>;
  @ViewChildren('verpackungBehaeltergroesseItem', { read: BewSelectComponent })
  verpackungBehaeltergroesseItemSelects!: QueryList<BewSelectComponent>;

  @ViewChildren('verpackungBehaeltergroesseItemEdit', {
    read: BewInputComponent,
  })
  verpackungBehaeltergroesseItemInputsEdit!: QueryList<BewInputComponent>;
  @ViewChildren('verpackungBehaeltergroesseItemEdit', {
    read: BewSelectComponent,
  })
  verpackungBehaeltergroesseItemSelectsEdit!: QueryList<BewSelectComponent>;

  public etikettenValid: boolean = true;

  ngAfterViewInit() {
    // This method is called once the view has been initialized
  }

  validateFormel() {
    const allgemeinValid = this.validateAllgemein();
    const zusammensetzungValid = this.validateZusammensetzung();
    const lagerungValid = this.validateLagerung();
    const verpackungValid = this.validateVerpackung();

    return (
      allgemeinValid && zusammensetzungValid && lagerungValid && verpackungValid
    );
  }

  validateAllgemein() {
    if (this.formel.allgemein.lohnherstellung == true) {
      const allgemeinValid = this.validateFields(
        this.allgemeinItemInputs,
        this.allgemeinItemSelects,
      );
      const lohnherstellerValid = this.validateFields(
        this.allgemeinLohnherstellerItemInputs,
        this.allgemeinLohnherstellerItemSelects,
      );
      return allgemeinValid && lohnherstellerValid;
    }
    return this.validateFields(
      this.allgemeinItemInputs,
      this.allgemeinItemSelects,
    );
  }

  validateZusammensetzung() {
    return this.validateFields(
      this.zusammensetzungItemInputs,
      this.zusammensetzungItemSelects,
    );
  }

  validateLagerung() {
    return this.validateFields(
      this.lagerungItemInputs,
      this.lagerungItemSelects,
    );
  }

  validateVerpackung() {
    const etikettenValid =
      this.formel &&
      this.formel.verpackung &&
      this.formel.verpackung.etiketten &&
      this.formel.verpackung.etiketten.size > 0;
    this.etikettenValid = etikettenValid;

    return this.validateFields(
      this.verpackungItemInputs,
      this.verpackungItemSelects,
    );
  }

  validateFields(
    inputs: QueryList<BewInputComponent>,
    selects: QueryList<BewSelectComponent>,
  ) {
    let invalid = false;
    inputs.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    selects.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    if (!this.validateEtiketten()) {
      invalid = true;
    }
    return !invalid;
  }

  validateEtiketten(): boolean {
    if (
      !(
        this.formel &&
        this.formel.verpackung &&
        this.formel.verpackung.etiketten &&
        this.formel.verpackung.etiketten.size > 0
      )
    ) {
      this.etikettenValid = false;
    } else {
      this.etikettenValid = true;
    }
    return this.etikettenValid;
  }

  validateKomponentBemerkung() {
    this.zusammensetzungKomponentBemerkung.valueRequired =
      this.formel.zusammensetzung.komponent.qualitaet ==
      KompenenteDraftDTO.QualitaetEnum.ANDERE;
    this.zusammensetzungKomponentBemerkung.validate();
  }

  validateKomponentBemerkungEdit() {
    this.zusammensetzungKomponentBemerkungEdit.valueRequired =
      this.editedKomponente.qualitaet ==
      KompenenteDraftDTO.QualitaetEnum.ANDERE;
    this.zusammensetzungKomponentBemerkungEdit.validate();
  }

  validateKomponentBezeichnung() {
    this.zusammensetzungKomponentBemerkung.valueRequired = true;
    this.zusammensetzungKomponentBemerkung.validate();
  }

  validateKomponente(): boolean {
    let invalid = false;
    this.zusammensetzungKomponentBemerkung.valueRequired =
      this.formel.zusammensetzung.komponent.qualitaet ==
      KompenenteDraftDTO.QualitaetEnum.ANDERE;
    this.zusammensetzungKomponentItemSelects.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    this.zusammensetzungKomponentItemInputs.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    return !invalid;
  }

  validateEditedKomponente(): boolean {
    let invalid = false;
    this.zusammensetzungKomponentBemerkungEdit.valueRequired =
      this.editedKomponente.qualitaet ==
      KompenenteDraftDTO.QualitaetEnum.ANDERE;
    this.zusammensetzungKomponentItemSelectsEdit.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    this.zusammensetzungKomponentItemInputsEdit.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    return !invalid;
  }

  clearKomponente() {
    this.formel.zusammensetzung.komponent.komponentOrder = '';
    this.formel.zusammensetzung.komponent.bezeichnung = '';
    this.formel.zusammensetzung.komponent.qualitaet = undefined;
    this.formel.zusammensetzung.komponent.art = undefined;
    this.formel.zusammensetzung.komponent.einheitsMenge = '';
    this.formel.zusammensetzung.komponent.einheit = undefined;
    this.formel.zusammensetzung.komponent.bemerkung = '';
  }

  clearEditedKomponente() {
    this.editedKomponente.komponentOrder = '';
    this.editedKomponente.bezeichnung = '';
    this.editedKomponente.qualitaet = undefined;
    this.editedKomponente.art = undefined;
    this.editedKomponente.einheitsMenge = '';
    this.editedKomponente.einheit = undefined;
    this.editedKomponente.bemerkung = '';
  }

  editedKomponente: Komponente = {
    komponentOrder: '',
    bezeichnung: '',
    qualitaet: undefined,
    art: undefined,
    einheitsMenge: '',
    einheit: undefined,
    bemerkung: '',
  };

  validateBehaeltergroesse(): boolean {
    let invalid = false;
    this.verpackungBehaeltergroesseItemSelects.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    this.verpackungBehaeltergroesseItemInputs.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    return !invalid;
  }

  validateEditedBehaeltergroesse(): boolean {
    let invalid = false;
    this.verpackungBehaeltergroesseItemSelectsEdit.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    this.verpackungBehaeltergroesseItemInputsEdit.forEach((component) => {
      component.validate();
      if (component.invalid) {
        invalid = true;
      }
    });
    return !invalid;
  }

  clearBehaeltergroesse() {
    this.formel.verpackung.behealterGroesse.packungsinhalt = '';
    this.formel.verpackung.behealterGroesse.einheit = undefined;
    this.formel.verpackung.behealterGroesse.bemerkung = '';
    this.formel.verpackung.behealterGroesse.artMaterialBehaelter = '';
  }

  clearEditedBehaeltergroesse() {
    this.editedBehalterGroesse.packungsinhalt = '';
    this.editedBehalterGroesse.einheit = undefined;
    this.editedBehalterGroesse.bemerkung = '';
    this.editedBehalterGroesse.artMaterialBehaelter = '';
  }

  editedBehalterGroesse: BehaelterGroesse = {
    packungsinhalt: '',
    einheit: undefined,
    artMaterialBehaelter: '',
    bemerkung: '',
  };

  formel: Formel = {
    id: undefined,
    state: undefined,
    allgemein: {
      praeparatName: '',
      praeparatArt: undefined,
      indikation: '',
      dosierungsVorschrift: '',
      darreichungsform: undefined,
      lohnherstellung: false,
      lohnhersteller: {
        name: '',
        betriebsArt: undefined,
        postfach: '',
        strasse: '',
        plz: '',
        ort: '',
      },
      files: List<FileDTO>(),
      notes: '',
    },
    zusammensetzung: {
      id: undefined,
      chargengroesse: '1',
      chargengroesseEinheit: undefined,
      komponent: {
        komponentOrder: '',
        bezeichnung: '',
        qualitaet: undefined,
        art: undefined,
        einheitsMenge: '',
        einheit: undefined,
        bemerkung: '',
      },
      komponenten: List.of(),
      files: List<FileDTO>(),
      notes: '',
    },
    lagerung: {
      lagerungshinweise: List.of(),
      haltbarkeitsDauer: '',
      bemerkung: '',
      files: List<FileDTO>(),
      notes: '',
    },
    verpackung: {
      id: undefined,
      behealterGroesse: {
        packungsinhalt: '',
        einheit: undefined,
        artMaterialBehaelter: '',
        bemerkung: '',
      },
      behaelterGroessen: List.of(),
      files: List<FileDTO>(),
      notes: '',
      etiketten: List<FileDTO>(),
    },
    pruefungsNotes: {
      notesAllgemein: '',
      notesZusammensetzung: '',
      notesLagerung: '',
      notesVerpackung: '',
    },
    betriebNotes: '',
  };

  formelRisikoParameterSelection: FormelRisikoParameterSelection = {
    anwendungsart: null,
    mengenverhaeltnis: null,
    wirkstoffrisiko: null,
    herstellungsprozess: null,
    arzneiform: null,
    produktionsmenge: null,
  };

  produktionsmengeTotal: Array<Produktionsmenge> =
    Object.values(Produktionsmenge);
  produktionsmenge: Array<Produktionsmenge> = [];

  risikoFaktor: number | any;

  produktionsmengen_selection = Array<Produktionsmenge>;

  abschiessenStates = [
    StateEnum.MELDEBESTAETIGUNGMITKOSTENFOLGE,
    StateEnum.MELDEBESTAETIGUNGOHNEKOSTENFOLGE,
    StateEnum.ZURNACHBEARBEITUNGZURUECKGEWIESEN,
  ];
  desiredAbschliessenState: StateEnum | undefined = undefined;

  saved: boolean = true;

  selectedLagerungshinweise: LagerungshinweiseEnum[] = [];

  isFormelWide = true;

  constructor(
    private readonly formelDraftApiService: FormelDraftApiService,
    private readonly formelApiService: FormelApiService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private formelDraftMapperService: FormelDraftMapperService,
    private formelMapperService: FormelMapperService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    public berechtigungService: BerechtigungService,
    public komponentApiService: KomponentApiService,
    private modalService: NgbModal,
  ) {}

  isDraft: boolean = false;
  isBerechtigtForFormel: boolean = false;
  contentMutable: boolean = false;
  padNotesMutable: boolean = false;
  betriebNotesMutable: boolean = false;
  betriebNotesVisible: boolean = false;

  showFormelPruefungTitle: boolean = false;
  showFormelCreateTitle: boolean = false;
  showFormelEditTitle: boolean = false;
  showFormelViewTitle: boolean = false;

  errorsEinreichen: [] = [];

  komponentSUggestions: KomponentSuggestionDTO[] = [];
  komponentSuggestionsEdit: KomponentSuggestionDTO[] = [];

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      let edit = false;
      if (params.edit == 'true') {
        edit = true;
      }
      this.changeDetectorRef.detectChanges();
      if (params.isDraft == 'true') {
        if (id != undefined) {
          this.formelDraftApiService
            .findById(id)
            .subscribe((formelDraftDTO) => {
              this.berechtigungService.currentBerechtigung.subscribe(
                (berechtigung) => {
                  const currentRolle = berechtigung?.rolle;
                  const currentBetriebId = berechtigung?.betrieb?.id;
                  const betriebIdDraft = formelDraftDTO.betrieb;
                  if (
                    currentBetriebId != undefined &&
                    currentBetriebId == betriebIdDraft &&
                    currentRolle != undefined &&
                    List.of(
                      RolleEnum.ADMINISTRATOR,
                      RolleEnum.EFOBETRIEBSLEITER,
                      RolleEnum.EFOBETRIEBSLEITERSTV,
                      RolleEnum.EFOBETRIEBSMITARBEITER,
                    ).contains(currentRolle)
                  ) {
                    this.initFormelDraft(formelDraftDTO, edit);
                  }
                },
              );
            });
        }
      } else {
        if (id != undefined) {
          this.berechtigungService.currentBerechtigung.subscribe(
            (berechtigung) => {
              const currentRolle = berechtigung?.rolle;
              const currentBetriebId = berechtigung?.betrieb?.id;
              if (
                currentBetriebId != undefined &&
                currentRolle != undefined &&
                List.of(
                  RolleEnum.ADMINISTRATOR,
                  RolleEnum.EFOBETRIEBSLEITER,
                  RolleEnum.EFOBETRIEBSLEITERSTV,
                  RolleEnum.EFOBETRIEBSMITARBEITER,
                ).contains(currentRolle)
              ) {
                this.formelApiService
                  .findByIdForBetrieb(id)
                  .subscribe((formelDTO) => {
                    this.initFormel(formelDTO, edit);
                  });
              } else if (
                currentBetriebId == undefined &&
                currentRolle != undefined &&
                List.of(
                  RolleEnum.ADMINISTRATOR,
                  RolleEnum.PADSEKRETARIAT,
                  RolleEnum.PADSACHBEARBEITER,
                ).contains(currentRolle)
              ) {
                this.formelApiService
                  .findByIdForPad(id)
                  .subscribe((formelDTO) => {
                    this.initFormel(formelDTO, edit);
                  });
              }
            },
          );
        }
      }
    });
  }

  ngOnDestroy() {
    this.clearSaveTimer();
  }

  private initFormelDraft(formelDraftDTO: FormelDraftDTO, edit: boolean) {
    this.isBerechtigtForFormel = true;
    this.contentMutable = edit;
    this.padNotesMutable = false;
    this.formel = this.formelDraftMapperService.mapDtoToFormel(formelDraftDTO);
    this.isDraft = true;
    this.initFormelRisiko(this.formel);
    this.syncSelectedLagerungshinweise();
    this.showFormelPruefungTitle = false;
    this.showFormelCreateTitle = true;
    this.showFormelEditTitle = false;
    this.showFormelViewTitle = false;
  }

  private initFormel(formelDTO: FormelDTO, edit: boolean) {
    this.isBerechtigtForFormel = true;
    this.contentMutable = false;
    this.padNotesMutable = false;

    this.showFormelPruefungTitle = formelDTO.state == 'IN_PRUEFUNG' && edit;
    this.showFormelCreateTitle = false;
    this.showFormelEditTitle = formelDTO.state == 'IN_ARBEIT' && edit;
    this.showFormelViewTitle = !(
      this.showFormelPruefungTitle ||
      this.showFormelCreateTitle ||
      this.showFormelEditTitle
    );

    if (
      formelDTO.state == 'IN_ARBEIT' &&
      this.berechtigungService.hasBetrieb()
    ) {
      this.contentMutable = edit;
      this.padNotesMutable = false;
    }
    if (
      formelDTO.state == 'IN_PRUEFUNG' &&
      (this.berechtigungService.isPad() || this.berechtigungService.isAdmin())
    ) {
      this.contentMutable = false;
      this.padNotesMutable = edit;
      this.betriebNotesVisible = true;
    }
    if (
      formelDTO.state == 'IN_PRUEFUNG' &&
      (this.berechtigungService.isBetrieb() ||
        this.berechtigungService.isAdmin()) &&
      this.berechtigungService.hasBetrieb()
    ) {
      this.betriebNotesVisible = true;
      this.betriebNotesMutable = true;
    }
    this.formel = this.formelMapperService.mapDtoToFormel(formelDTO);
    this.isDraft = false;
    this.initFormelRisiko(this.formel);
    this.syncSelectedLagerungshinweise();
  }

  private initFormelRisiko(formel: Formel) {
    if (formel.formelRisiko) {
      this.formelRisikoParameterSelection =
        formel.formelRisiko.formelRisikoParameter;
      this.risikoFaktor = formel.formelRisiko.risikoFaktor;
      this.adjustProduktionsmengen();
    }
  }

  selectKomponentSuggestion(k: any) {
    this.formel.zusammensetzung.komponent.bezeichnung = k.name;
    const qualiteat = this.getQualteat(k.source);
    if (qualiteat != undefined) {
      this.formel.zusammensetzung.komponent.qualitaet = qualiteat;
    }
    this.komponentSUggestions = [];
  }

  selectKomponentSuggestionEdit(k: any) {
    this.editedKomponente.bezeichnung = k.name;
    const qualiteat = this.getQualteat(k.source);
    if (qualiteat != undefined) {
      this.editedKomponente.qualitaet = qualiteat;
    }
    this.komponentSuggestionsEdit = [];
  }

  getQualteat(source: string) {
    if (source == 'Ph. Eur.') {
      return 'PH_EUR';
    }
    if (source == 'Ph. Helv.12') {
      return 'PH_HELV';
    }
    return undefined;
  }

  get showParentFormel() {
    // We dont display the Parent for Betrieb Berechtigungen if its in MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN
    return (
      this.formel &&
      this.formel.parent &&
      (this.berechtigungService.isAdminOrPad() ||
        this.formel.parent.state != 'MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN')
    );
  }

  searchKomponentenSuggestions() {
    if (
      this.formel.zusammensetzung.komponent.bezeichnung != undefined &&
      this.formel.zusammensetzung.komponent.bezeichnung != ''
    ) {
      this.komponentApiService
        .search(this.formel.zusammensetzung.komponent.bezeichnung)
        .subscribe((data) => {
          if (data.content != undefined) {
            this.komponentSUggestions = data.content;
          } else {
            this.komponentSUggestions = [];
          }
        });
    } else {
      this.komponentSUggestions = [];
      this.formel.zusammensetzung.komponent.qualitaet = undefined;
    }
  }

  searchKomponentenSuggestionsEdit() {
    if (
      this.editedKomponente.bezeichnung != undefined &&
      this.editedKomponente.bezeichnung != ''
    ) {
      this.komponentApiService
        .search(this.editedKomponente.bezeichnung)
        .subscribe((data) => {
          if (data.content != undefined) {
            this.komponentSuggestionsEdit = data.content;
          } else {
            this.komponentSuggestionsEdit = [];
          }
        });
    } else {
      this.komponentSuggestionsEdit = [];
      this.editedKomponente.qualitaet = undefined;
    }
  }

  updateLagerungshinweise() {
    this.formel.lagerung.lagerungshinweise = List(
      this.selectedLagerungshinweise,
    );
    this.invokeValueChange();
  }

  syncSelectedLagerungshinweise() {
    this.selectedLagerungshinweise =
      this.formel.lagerung.lagerungshinweise.toArray();
  }

  drop(event: CdkDragDrop<string[]>) {
    const prevIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    const k = this.formel.zusammensetzung.komponenten.get(prevIndex);
    if (k) {
      this.formel.zusammensetzung.komponenten =
        this.formel.zusammensetzung.komponenten
          .delete(prevIndex)
          .insert(currentIndex, k);
    }
    this.sortKomponenten();
    this.invokeValueChange();
  }

  public deleteKomponente(index: number) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = ['modal.confirm.delete.komponente'];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';
    modalRef.result.then((result) => {
      if (result) {
        if (
          this.formel.zusammensetzung.komponenten != null &&
          this.formel.zusammensetzung.komponenten.size > index
        ) {
          this.formel.zusammensetzung.komponenten =
            this.formel.zusammensetzung.komponenten.delete(index);
          this.sortKomponenten();
          this.invokeValueChange();
          this.toggledKomponentEdit = null;
        }
      }
    });
  }

  public sortKomponenten() {
    this.formel.zusammensetzung.komponenten.forEach((komponente, index) => {
      komponente.komponentOrder = index + 1 + '';
    });
  }

  abschliessen() {
    if (
      this.desiredAbschliessenState != undefined &&
      this.formel.id != undefined
    ) {
      const updateFormelStateDTO: UpdateFormelStateDTO = {
        formelId: this.formel.id,
        desiredState: this.desiredAbschliessenState,
      };
      this.formelApiService
        .updateState(updateFormelStateDTO)
        .subscribe((id) => {
          this.router.navigate([PathAsString.get(routes.formeln)]);
        });
    }
  }

  mutable(): boolean {
    return (
      this.isDraft || (!this.isDraft && this.formel.state == StateEnum.INARBEIT)
    );
  }

  saveable(): boolean {
    return (
      this.contentMutable || this.padNotesMutable || this.betriebNotesMutable
    );
  }

  copyable(): boolean {
    return (
      this.formel.state == StateEnum.INARBEIT ||
      this.formel.state == StateEnum.INPRUEFUNG ||
      this.formel.state == StateEnum.VERZICHTET
    );
  }

  cloneable(): boolean {
    return (
      this.formel.state == StateEnum.MELDEBESTAETIGUNGMITKOSTENFOLGE ||
      this.formel.state == StateEnum.MELDEBESTAETIGUNGOHNEKOSTENFOLGE
    );
  }

  public updateAllgemeinFiles(files: List<FileDTO>) {
    if (this.formel.allgemein.files.size != files.size) {
      this.invokeValueChange();
    }
    this.formel.allgemein.files = files;
  }

  public updateZusammensetzungFiles(files: List<FileDTO>) {
    if (this.formel.zusammensetzung.files.size != files.size) {
      this.invokeValueChange();
    }
    this.formel.zusammensetzung.files = files;
  }

  public updatelagerungFiles(files: List<FileDTO>) {
    if (this.formel.lagerung.files.size != files.size) {
      this.invokeValueChange();
    }
    this.formel.lagerung.files = files;
  }

  public updateVerpackungFiles(files: List<FileDTO>) {
    if (this.formel.verpackung.files.size != files.size) {
      this.invokeValueChange();
    }
    this.formel.verpackung.files = files;
  }

  public updateEtiketten(etiketten: List<FileDTO>) {
    const changed = this.formel.verpackung.etiketten.size != etiketten.size;
    this.formel.verpackung.etiketten = etiketten;
    if (changed) {
      this.invokeValueChange();
      this.validateEtiketten();
    }
  }

  public update() {
    this.clearSaveTimer();
    if (this.isDraft) {
      this.formelDraftApiService
        .update(
          this.formelDraftMapperService.mapFormelToUpdateFormelDraftContentDTO(
            this.formel,
          ),
        )
        .subscribe((id) => {
          this.formel.id = id;
          this.saved = true;
        });
    } else if (this.formel.state == StateEnum.INARBEIT) {
      this.formelApiService
        .updateContent(
          this.formelMapperService.mapFormelToUpdateFormelContentDTO(
            this.formel,
          ),
        )
        .subscribe((id) => {
          this.formel.id = id;
          this.saved = true;
        });
    } else if (this.padNotesMutable) {
      this.formelApiService
        .updatePruefungsNotes(
          this.formelMapperService.mapFormelToUpdatePruefungsNotesDTO(
            this.formel,
          ),
        )
        .subscribe((id) => {
          this.formel.id = id;
          this.saved = true;
        });
    } else if (this.betriebNotesMutable) {
      this.formelApiService
        .updateBetriebNotes(
          this.formelMapperService.mapFormelToUpdateBetriebNotesDTO(
            this.formel,
          ),
        )
        .subscribe((id) => {
          this.formel.id = id;
          this.saved = true;
        });
    }
  }

  public copy() {
    this.executeCopy();
  }

  private executeCopy() {
    if (!this.isDraft && this.formel.id) {
      let copyFormelDto: CopyFormelDTO = {
        formelId: this.formel.id,
        language: this.translateService.currentLang,
      };

      this.formelApiService.copyFormel(copyFormelDto).subscribe(() => {
        this.router.navigate([PathAsString.get(routes.formeln)]);
      });
    }
  }

  private executeDeepClone() {
    if (!this.isDraft && this.formel.id) {
      let cloneFormelDto: CloneFormelDTO = {
        formelId: this.formel.id,
        language: this.translateService.currentLang,
      };

      this.formelApiService.cloneFormel(cloneFormelDto).subscribe(() => {
        this.router.navigate([PathAsString.get(routes.formeln)]);
      });
    }
  }

  public isFormelArchivable(): boolean {
    return (
      this.formel &&
      (this.formel.state == 'MELDEBESTAETIGUNG_MIT_KOSTENFOLGE' ||
        this.formel.state == 'MELDEBESTAETIGUNG_OHNE_KOSTENFOLGE' ||
        this.formel.state == 'MELDEBESTAETIGUNG_NEUE_VERSION_VORHANDEN')
    );
  }

  public archive(): void {
    if (this.formel && this.formel.id) {
      this.formelApiService.achiveFormel(this.formel.id).subscribe(() => {
        this.router.navigate([PathAsString.get(routes.formeln)]);
      });
    }
  }

  public einreichen() {
    this.validateFormel();
    if (this.isDraft) {
      this.berechtigungService.currentBetrieb
        .pipe(first())
        .subscribe((betrieb) => {
          if (betrieb && betrieb.id) {
            this.formelApiService
              .create(
                this.formelMapperService.mapFormelToCreateFormelDTO(
                  this.formel,
                  betrieb.id,
                ),
              )
              .subscribe(
                (id) => {
                  this.router.navigate([PathAsString.get(routes.formeln)]);
                },
                (error) => {
                  this.errorsEinreichen = error.error;
                },
              );
          }
        });
    } else if (
      !this.isDraft &&
      this.formel.state == StateEnum.INARBEIT &&
      this.formel.id != undefined
    ) {
      const updateFormelStateDTO: UpdateFormelStateDTO = {
        formelId: this.formel.id,
        desiredState: StateEnum.INPRUEFUNG,
      };
      this.formelApiService
        .updateState(updateFormelStateDTO)
        .subscribe((id) => {
          this.router.navigate([PathAsString.get(routes.formeln)]);
        });
    }
  }

  public addBehaelterGroesse() {
    if (this.validateBehaeltergroesse()) {
      this.formel.verpackung.behaelterGroessen =
        this.formel.verpackung.behaelterGroessen.push({
          packungsinhalt:
            this.formel.verpackung.behealterGroesse.packungsinhalt,
          einheit: this.undefinedIfEmpty(
            this.formel.verpackung.behealterGroesse.einheit,
          ),
          bemerkung: this.formel.verpackung.behealterGroesse.bemerkung,
          artMaterialBehaelter:
            this.formel.verpackung.behealterGroesse.artMaterialBehaelter,
        });
      this.clearBehaeltergroesse();

      this.invokeValueChange();
    }
  }

  updateBehaelterGroesse(b: BehaelterGroesse) {
    if (this.validateEditedBehaeltergroesse()) {
      b.packungsinhalt = this.editedBehalterGroesse.packungsinhalt;
      b.einheit = this.editedBehalterGroesse.einheit;
      b.artMaterialBehaelter = this.editedBehalterGroesse.artMaterialBehaelter;
      b.bemerkung = this.editedBehalterGroesse.bemerkung;
      this.clearEditedBehaeltergroesse();
      this.toggledBehaelterGroesseEdit = null;
      this.invokeValueChange();
    }
  }

  public cancelBehaelterGroesseEdit() {
    this.toggledBehaelterGroesseEdit = null;
    this.clearEditedBehaeltergroesse();
  }

  public toggleBehaelterGroesseEdit(index: number, b: BehaelterGroesse) {
    if (this.toggledBehaelterGroesseEdit != index) {
      this.toggledBehaelterGroesseEdit = index;
      this.editedBehalterGroesse.packungsinhalt = b.packungsinhalt;
      this.editedBehalterGroesse.einheit = b.einheit;
      this.editedBehalterGroesse.artMaterialBehaelter = b.artMaterialBehaelter;
      this.editedBehalterGroesse.bemerkung = b.bemerkung;
    } else {
      this.cancelBehaelterGroesseEdit();
    }
  }

  public deleteBehaelterGroesse(index: number) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = [
      'modal.confirm.delete.behaeltergroesse',
    ];
    modalRef.componentInstance.type = 'CONFIRM_DELETE';
    modalRef.result.then((result) => {
      if (result) {
        if (
          this.formel.verpackung.behealterGroesse != null &&
          this.formel.verpackung.behaelterGroessen.size > index
        ) {
          this.formel.verpackung.behaelterGroessen =
            this.formel.verpackung.behaelterGroessen.delete(index);
          this.invokeValueChange();
        }
      }
    });
  }

  public editBehaelterGroesse(behaelterGroesse: BehaelterGroesse) {
    if (behaelterGroesse != null) {
      if (behaelterGroesse.packungsinhalt != undefined) {
        this.formel.verpackung.behealterGroesse.packungsinhalt =
          behaelterGroesse.packungsinhalt;
      } else {
        this.formel.verpackung.behealterGroesse.packungsinhalt = '';
      }
      this.formel.verpackung.behealterGroesse.einheit =
        behaelterGroesse.einheit;
      if (behaelterGroesse.bemerkung != undefined) {
        this.formel.verpackung.behealterGroesse.bemerkung =
          behaelterGroesse.bemerkung;
      } else {
        this.formel.verpackung.behealterGroesse.bemerkung = '';
      }
      if (behaelterGroesse.artMaterialBehaelter != undefined) {
        this.formel.verpackung.behealterGroesse.artMaterialBehaelter =
          behaelterGroesse.artMaterialBehaelter;
      } else {
        this.formel.verpackung.behealterGroesse.artMaterialBehaelter = '';
      }
    }
  }

  public addKomponente() {
    if (this.validateKomponente()) {
      this.formel.zusammensetzung.komponenten =
        this.formel.zusammensetzung.komponenten.push({
          komponentOrder: this.formel.zusammensetzung.komponenten.size + 2 + '',
          bezeichnung: this.formel.zusammensetzung.komponent.bezeichnung,
          qualitaet: this.formel.zusammensetzung.komponent.qualitaet,
          art: this.formel.zusammensetzung.komponent.art,
          einheitsMenge: this.formel.zusammensetzung.komponent.einheitsMenge,
          einheit: this.formel.zusammensetzung.komponent.einheit,
          bemerkung: this.formel.zusammensetzung.komponent.bemerkung,
        });
      this.clearKomponente();
      this.sortKomponenten();
      this.invokeValueChange();
    }
  }

  updateKomponente(k: Komponente) {
    if (this.validateEditedKomponente()) {
      k.bezeichnung = this.editedKomponente.bezeichnung;
      k.qualitaet = this.editedKomponente.qualitaet;
      k.art = this.editedKomponente.art;
      k.einheitsMenge = this.editedKomponente.einheitsMenge;
      k.einheit = this.editedKomponente.einheit;
      k.bemerkung = this.editedKomponente.bemerkung;

      this.clearEditedKomponente();
      this.sortKomponenten();
      this.toggledKomponentEdit = null;
      this.invokeValueChange();
    }
  }

  toggledKomponentEdit: number | null = null;
  toggledBehaelterGroesseEdit: number | null = null;

  public toggleKomponentEdit(index: number, k: Komponente) {
    if (this.toggledKomponentEdit != index) {
      this.toggledKomponentEdit = index;
      this.editedKomponente.bezeichnung = k.bezeichnung;
      this.editedKomponente.qualitaet = k.qualitaet;
      this.editedKomponente.art = k.art;
      this.editedKomponente.einheitsMenge = k.einheitsMenge;
      this.editedKomponente.einheit = k.einheit;
      this.editedKomponente.bemerkung = k.bemerkung;
    } else {
      this.cancelKomponentEdit();
    }
  }

  public cancelKomponentEdit() {
    this.toggledKomponentEdit = null;
    this.clearEditedKomponente();
  }

  public editKomponente(komponente: Komponente) {
    if (komponente != null) {
      if (komponente.komponentOrder != undefined) {
        this.formel.zusammensetzung.komponent.komponentOrder =
          komponente.komponentOrder;
      } else {
        this.formel.zusammensetzung.komponent.komponentOrder = '';
      }
      if (komponente.bezeichnung != undefined) {
        this.formel.zusammensetzung.komponent.bezeichnung =
          komponente.bezeichnung;
      } else {
        this.formel.zusammensetzung.komponent.bezeichnung = '';
      }
      this.formel.zusammensetzung.komponent.qualitaet = komponente.qualitaet;
      this.formel.zusammensetzung.komponent.art = komponente.art;
      if (komponente.einheitsMenge != undefined) {
        this.formel.zusammensetzung.komponent.einheitsMenge =
          komponente.einheitsMenge;
      } else {
        this.formel.zusammensetzung.komponent.einheitsMenge = '';
      }
      this.formel.zusammensetzung.komponent.einheit = komponente.einheit;
      if (komponente.bemerkung != undefined) {
        this.formel.zusammensetzung.komponent.bemerkung = komponente.bemerkung;
      } else {
        this.formel.zusammensetzung.komponent.bemerkung = '';
      }
    }
  }

  public invokeValueChange() {
    this.saved = false;
    this.startSaveTimer();
  }

  private saveTimer: any;

  startSaveTimer() {
    this.clearSaveTimer();
    this.saveTimer = setTimeout(() => {
      this.update();
    }, 5000);
  }

  clearSaveTimer() {
    if (this.saveTimer) {
      clearTimeout(this.saveTimer);
    }
  }

  public toStringOrEmpty(value: Number | undefined) {
    if (value == undefined) {
      return '';
    }
    return value.toString();
  }

  public valueOrEmpty(value: string | undefined) {
    if (value == undefined) {
      return '';
    }
    return value;
  }

  private valueOrFalse(value: boolean | undefined) {
    if (value == undefined) {
      return false;
    }
    return value;
  }

  private undefinedIfEmpty(value: any) {
    if (value != undefined && value == '') {
      return undefined;
    }
    return value;
  }

  public arzneiFormChanged() {
    this.resetSelection();
    this.adjustProduktionsmengen();
  }

  public resetSelection() {
    this.risikoFaktor = undefined;
  }

  private adjustProduktionsmengen() {
    let name = JSON.parse(this.formelRisikoParameterSelection.arzneiform).name;

    if (name == 'FLUESSIG') {
      this.pickTheProduktonsmenge(2000);
    } else if (name == 'HALBFEST_ZAEPFCHEN') {
      this.pickTheProduktonsmenge(2200);
    } else if (name == 'FEST') {
      this.pickTheProduktonsmenge(2100);
    } else if (name == 'HALBFEST') {
      this.pickTheProduktonsmenge(2300);
    } else if (name == 'AUGENTROPFEN') {
      this.pickTheProduktonsmenge(2400);
    } else {
      this.produktionsmenge = [];
    }
  }

  private pickTheProduktonsmenge(range: any) {
    this.produktionsmenge = this.sortEnumByRiskFactor(
      this.produktionsmengeTotal.filter(
        (element) =>
          JSON.parse(element).code - range >= 0 &&
          JSON.parse(element).code - range <= 4,
      ),
    );
  }

  private sortEnumByRiskFactor(array: Array<any>) {
    return array.sort(this.riskFactorComparator);
  }

  private riskFactorComparator(object1: any, object2: any): number {
    if (JSON.parse(object1).riskFactor < JSON.parse(object2).riskFactor)
      return -1;
    if (JSON.parse(object1).riskFactor > JSON.parse(object2).riskFactor)
      return 1;
    return 0;
  }

  formelRisikoBerechnen() {
    let formelRisikoParameterDTO: FormelRisikoParameterDTO = {
      anwendungsart: this.formelRisikoParameterSelection.anwendungsart,
      mengenverhaeltnis: this.formelRisikoParameterSelection.mengenverhaeltnis,
      wirkstoffrisiko: this.formelRisikoParameterSelection.wirkstoffrisiko,
      herstellungsprozess:
        this.formelRisikoParameterSelection.herstellungsprozess,
      arzneiform: this.formelRisikoParameterSelection.arzneiform,
      produktionsmenge: this.formelRisikoParameterSelection.produktionsmenge,
    };

    this.formelApiService
      .calculateFormelRisiko(formelRisikoParameterDTO)
      .subscribe((res) => {
        this.risikoFaktor = res;
        this.formel.formelRisiko = {
          formelRisikoParameter: this.formelRisikoParameterSelection,
          risikoFaktor: res,
        };
        this.invokeValueChange();
      });
  }

  public getRisikofaktorResultText(): String {
    if (this.risikoFaktor == undefined) {
      return '';
    }
    if (this.risikoFaktor < 100) {
      return this.translateService.instant(
        'formel.detail.risikorechner.text_unter_100',
        {
          resultat: Number.isInteger(this.risikoFaktor.valueOf())
            ? this.risikoFaktor
            : this.risikoFaktor.toFixed(1),
        },
      );
    } else {
      return this.translateService.instant(
        'formel.detail.risikorechner.text_ueber_100',
        {
          resultat: Number.isInteger(this.risikoFaktor.valueOf())
            ? this.risikoFaktor
            : this.risikoFaktor.toFixed(1),
        },
      );
    }
  }

  public getProduktionsmengenLabelKey(): string {
    if (
      this.formelRisikoParameterSelection &&
      this.formelRisikoParameterSelection.arzneiform
    ) {
      return (
        'formel.detail.risikorechner.form.produktionsmenge.' +
        JSON.parse(
          this.formelRisikoParameterSelection.arzneiform,
        ).name.toLowerCase()
      );
    }
    return 'formel.detail.risikorechner.form.produktionsmenge.label';
  }

  isBerechtigtForDraft(): Observable<boolean> {
    return this.berechtigungService.isAdminOrHasAnyRolleAndBetrieb([
      RolleEnum.EFOBETRIEBSMITARBEITER,
      RolleEnum.EFOBETRIEBSLEITERSTV,
      RolleEnum.EFOBETRIEBSLEITER,
    ]);
  }

  isLagerungshinweisSelected(
    langerungshinweis: LagerungshinweiseEnum,
  ): boolean {
    return this.selectedLagerungshinweise.includes(langerungshinweis);
  }

  toggleLagerungshinweis(langerungshinweis: LagerungshinweiseEnum) {
    if (this.isLagerungshinweisSelected(langerungshinweis)) {
      this.selectedLagerungshinweise = this.selectedLagerungshinweise.filter(
        (item) => item !== langerungshinweis,
      );
    } else {
      this.selectedLagerungshinweise.push(langerungshinweis);
    }
    this.updateLagerungshinweise();
  }

  showEinreichen() {
    return this.isDraft || this.formel.state == 'IN_ARBEIT';
  }

  toggleFormelWide() {
    this.isFormelWide = !this.isFormelWide;
  }

  protected readonly Object = Object;
  protected readonly undefined = undefined;
  protected readonly BehaelterGroesseDraftDTO = BehaelterGroesseDraftDTO;
  protected readonly LagerungDraftDTO = LagerungDraftDTO;
  protected readonly KompenenteDraftDTO = KompenenteDraftDTO;
  protected readonly ZusammensetzungDraftDTO = ZusammensetzungDraftDTO;
  protected readonly LohnherstellerDraftDTO = LohnherstellerDraftDTO;
  protected readonly AllgemeinDraftDTO = AllgemeinDraftDTO;
  protected readonly JSON = JSON;
  protected readonly Anwendungsart = Anwendungsart;
  protected readonly Mengenverhaeltnis = Mengenverhaeltnis;
  protected readonly Wirkstoffrisiko = Wirkstoffrisiko;
  protected readonly Herstellungsprozess = Herstellungsprozess;
  protected readonly Arzneiform = Arzneiform;
  protected readonly console = console;
}

export interface Formel {
  id: string | undefined;
  state: StateEnum | undefined;
  allgemein: Allgemein;
  zusammensetzung: Zusammensetzung;
  lagerung: Lagerung;
  verpackung: Verpackung;
  pruefungsNotes: PruefungsNotes;
  betriebNotes: string;
  formelRisiko?: FormelRisiko;
  parent?: Formel;
}

export interface Allgemein {
  praeparatName: string;
  praeparatArt: PraeparatArtEnum | undefined;
  indikation: string;
  dosierungsVorschrift: string;
  darreichungsform: DarreichungsformEnum | undefined;
  lohnherstellung: boolean;
  lohnhersteller: Lohnhersteller;
  files: List<FileDTO>;
  notes: string;
}

export interface Zusammensetzung {
  id: string | undefined;
  chargengroesse: string;
  chargengroesseEinheit: ChargengroesseEinheitEnum | undefined;
  komponent: Komponente;
  komponenten: List<Komponente>;
  files: List<FileDTO>;
  notes: string;
}

export interface Lagerung {
  lagerungshinweise: List<LagerungshinweiseEnum>;
  haltbarkeitsDauer: string;
  bemerkung: string;
  files: List<FileDTO>;
  notes: string;
}

export interface Verpackung {
  id: string | undefined;
  behealterGroesse: BehaelterGroesse;
  behaelterGroessen: List<BehaelterGroesse>;
  files: List<FileDTO>;
  notes: string;
  etiketten: List<FileDTO>;
}

export interface Lohnhersteller {
  name: string;
  betriebsArt: LohnherstellerDraftDTO.BetriebsArtEnum | undefined;
  postfach: string;
  strasse: string;
  plz: string;
  ort: string;
}

export interface Komponente {
  komponentOrder: string;
  bezeichnung: string;
  qualitaet: KompenenteDraftDTO.QualitaetEnum | undefined;
  art: KompenenteDraftDTO.ArtEnum | undefined;
  einheitsMenge: string;
  einheit: KompenenteDraftDTO.EinheitEnum | undefined;
  bemerkung: string;
}

export interface BehaelterGroesse {
  packungsinhalt: string;
  einheit: BehaelterGroesseDraftDTO.EinheitEnum | undefined;
  artMaterialBehaelter: string;
  bemerkung: string;
}

export interface PruefungsNotes {
  notesAllgemein: string;
  notesZusammensetzung: string;
  notesLagerung: string;
  notesVerpackung: string;
}

export interface FormelRisiko {
  formelRisikoParameter: FormelRisikoParameter;
  risikoFaktor: number;
}

export interface FormelRisikoParameter {
  anwendungsart: Anwendungsart;
  mengenverhaeltnis: Mengenverhaeltnis;
  wirkstoffrisiko: Wirkstoffrisiko;
  herstellungsprozess: Herstellungsprozess;
  arzneiform: Arzneiform;
  produktionsmenge: Produktionsmenge;
}

export interface FormelRisikoParameterSelection {
  anwendungsart: Anwendungsart | any;
  mengenverhaeltnis: Mengenverhaeltnis | any;
  wirkstoffrisiko: Wirkstoffrisiko | any;
  herstellungsprozess: Herstellungsprozess | any;
  arzneiform: Arzneiform | any;
  produktionsmenge: Produktionsmenge | any;
}
