<app-error-no-berechtigung-for-site
  *ngIf="!isBerechtigtForFormel"></app-error-no-berechtigung-for-site>

<div *ngIf="isBerechtigtForFormel">
  <h1
    *ngIf="showFormelCreateTitle"
    [innerText]="'formel.detail.title.create' | translate"></h1>
  <h1
    *ngIf="showFormelEditTitle"
    [innerText]="'formel.detail.title.edit' | translate"></h1>
  <h1
    *ngIf="showFormelPruefungTitle"
    [innerText]="'formel.detail.title.pruefung' | translate"></h1>
  <h1
    *ngIf="showFormelViewTitle"
    [innerText]="'formel.detail.title.view' | translate"></h1>
  <div class="row" *ngIf="formel.parent && showParentFormel">
    <div class="col offset-1">
      {{ 'formel.parentLabel' | translate }}:
      <a
        href="#"
        routerLink="/erfassen"
        [queryParams]="{ id: formel.parent.id, isDraft: false }"
        >{{ formel.parent.allgemein.praeparatName }}</a
      >
    </div>
  </div>
  <div class="row">
    <div class="col-1">
      <div *ngIf="saveable()">
        <button
          class="efo-button green"
          *ngIf="saved"
          (click)="update()"
          style="position: fixed"
          [ngbTooltip]="tooltipFormelSave"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300"
          placement="right">
          <i class="fa fa-save"></i>
        </button>
        <button
          class="efo-button orange"
          *ngIf="!saved"
          (click)="update()"
          style="position: fixed"
          [ngbTooltip]="tooltipFormelSave"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300"
          placement="right">
          <i class="fa fa-save"></i>
        </button>
      </div>
      <div style="margin-top: 60px">
        <button
          class="efo-button green"
          *ngIf="berechtigungService.hasBetrieb() && !isDraft && copyable()"
          (click)="copy()"
          style="position: fixed"
          [ngbTooltip]="tooltipFormelCopy"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300"
          placement="right">
          <i class="fa fa-copy"></i>
        </button>
      </div>
      <div>
        <button
          type="button"
          class="efo-button green"
          *ngIf="isFormelArchivable() && berechtigungService.hasBetrieb()"
          data-bs-toggle="modal"
          data-bs-target="#archiveWarning"
          style="position: fixed; margin-top: 60px"
          [ngbTooltip]="tooltipFormelArchive"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300"
          placement="right">
          <i class="fa fa-crosshairs"></i>
        </button>
        <button
          type="button"
          class="efo-button green"
          *ngIf="
            !isFormelArchivable() &&
            berechtigungService.hasBetrieb() &&
            formel.state != 'VERZICHTET'
          "
          data-bs-toggle="modal"
          data-bs-target="#archiveHint"
          style="position: fixed; margin-top: 60px"
          [ngbTooltip]="tooltipFormelArchive"
          triggers="hover"
          [openDelay]="500"
          [closeDelay]="300"
          placement="right">
          <i class="fa fa-crosshairs"></i>
        </button>
      </div>
    </div>
    <div class="col-11">
      <div class="row">
        <app-bew-box
          class="main-box"
          [ngClass]="isFormelWide ? 'col-12' : 'col-9'"
          [label]="'formel.detail.betrieb.notes.title' | translate"
          *ngIf="betriebNotesVisible">
          <app-bew-textarea
            style="height: max-content !important"
            [label]="'formel.detail.betrieb.notes.title' | translate"
            [(value)]="formel.betriebNotes"
            [disabled]="!betriebNotesMutable"
            (valueChange)="invokeValueChange()">
          </app-bew-textarea>
        </app-bew-box>
      </div>
      <div class="row">
        <div [ngClass]="isFormelWide ? 'col-12' : 'col-9'">
          <app-bew-box
            class="main-box"
            [label]="'formel.detail.allgemein.title' | translate">
            <div
              [innerHTML]="'formel.detail.allgemein.title' | translate"></div>

            <app-bew-input
              #allgemeinPreaparatName
              #allgemeinItem
              placement="top"
              [ngbTooltip]="tooltipAllgemeinPreaparatName"
              [label]="
                'formel.detail.allgemein.preaparat.name.title' | translate
              "
              [(value)]="formel.allgemein.praeparatName"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="
                'error.formel.allgemein.praeparat.name.blank'
              "></app-bew-input>
            <div
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="{{
                'formel.detail.allgemein.preaparat.name.tooltip' | translate
              }}"></div>

            <app-bew-select
              #allgemeinPreaparatArt
              #allgemeinItem
              placement="top"
              [ngbTooltip]="tooltipAllgemeinPreaparatArt"
              [disabled]="!contentMutable"
              [(value)]="formel.allgemein.praeparatArt"
              [items]="Object.values(AllgemeinDraftDTO.PraeparatArtEnum)"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="'error.formel.allgemein.praeparat.art.null'"
              [prefix]="'enum.praeperatart.'"
              [label]="
                'formel.detail.allgemein.preaparat.art.title' | translate
              ">
            </app-bew-select>

            <app-bew-input
              #allgemeinIndikation
              #allgemeinItem
              placement="top"
              [ngbTooltip]="tooltipAllgemeinIndikation"
              [label]="'formel.detail.allgemein.indikation.title' | translate"
              [(value)]="formel.allgemein.indikation"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="'error.formel.allgemein.indikation.blank'">
            </app-bew-input>

            <app-bew-input
              #allgemeinDosierungsvorschriften
              #allgemeinItem
              placement="top"
              [ngbTooltip]="tooltipAllgemeinDosierungsvorschriften"
              [label]="
                'formel.detail.allgemein.dosierungsvorschriften.title'
                  | translate
              "
              [(value)]="formel.allgemein.dosierungsVorschrift"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="'error.formel.allgemein.dosierungsvorschrift.blank'">
            </app-bew-input>

            <app-bew-select
              #allgemeinDarreichungsform
              #allgemeinItem
              placement="top"
              [ngbTooltip]="tooltipAllgemeinDarreichungsform"
              [disabled]="!contentMutable"
              [(value)]="formel.allgemein.darreichungsform"
              [items]="Object.values(AllgemeinDraftDTO.DarreichungsformEnum)"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="'error.formel.allgemein.darreichungsform.null'"
              [prefix]="'enum.darreichungsform.'"
              [label]="
                'formel.detail.allgemein.darreichungsform.title' | translate
              ">
            </app-bew-select>

            <app-bew-box
              [label]="
                'formel.detail.allgemein.lohnherstellung.title' | translate
              ">
              <app-bew-checkbox
                placement="top"
                [ngbTooltip]="tooltipAllgemeinLohnherstellung"
                [label]="
                  'formel.detail.allgemein.lohnherstellung.title' | translate
                "
                [(ngModel)]="formel.allgemein.lohnherstellung"
                [disabled]="!contentMutable"
                (change)="invokeValueChange()">
              </app-bew-checkbox>

              <div *ngIf="formel.allgemein.lohnherstellung">
                <app-bew-input
                  #allgemeinLohnherstellerBetriebsname
                  #allgemeinLohnherstellerItem
                  placement="top"
                  [ngbTooltip]="tooltipAllgemeinLohnherstellung"
                  [label]="
                    'formel.detail.allgemein.lohnhersteller.betriebsname'
                      | translate
                  "
                  [(value)]="formel.allgemein.lohnhersteller.name"
                  [maxlength]="150"
                  [disabled]="!contentMutable"
                  (valueChange)="invokeValueChange()"
                  [valueRequired]="true"
                  [validateOnTouch]="true"
                  [error]="'error.formel.allgemein.lohnhersteller.name.blank'">
                </app-bew-input>

                <app-bew-select
                  #allgemeinLohnherstellerBetriebsart
                  #allgemeinLohnherstellerItem
                  [disabled]="!contentMutable"
                  [(value)]="formel.allgemein.lohnhersteller.betriebsArt"
                  [items]="
                    Object.values(LohnherstellerDraftDTO.BetriebsArtEnum)
                  "
                  (valueChange)="invokeValueChange()"
                  [valueRequired]="true"
                  [validateOnTouch]="true"
                  [error]="
                    'error.formel.allgemein.lohnhersteller.betriebsart.null'
                  "
                  [prefix]="'enum.betriebsart.'"
                  [label]="
                    'formel.detail.allgemein.lohnhersteller.betriebsart'
                      | translate
                  ">
                </app-bew-select>

                <app-bew-input
                  #allgemeinLohnherstellerStrasse
                  #allgemeinLohnherstellerItem
                  [label]="
                    'formel.detail.allgemein.lohnhersteller.strasse' | translate
                  "
                  [(value)]="formel.allgemein.lohnhersteller.strasse"
                  [maxlength]="50"
                  [disabled]="!contentMutable"
                  (valueChange)="invokeValueChange()"
                  [valueRequired]="true"
                  [validateOnTouch]="true"
                  [error]="
                    'error.formel.allgemein.lohnhersteller.strasse.blank'
                  ">
                </app-bew-input>

                <app-bew-input
                  #allgemeinLohnherstellerPlz
                  #allgemeinLohnherstellerItem
                  [label]="
                    'formel.detail.allgemein.lohnhersteller.plz' | translate
                  "
                  [(value)]="formel.allgemein.lohnhersteller.plz"
                  [maxlength]="10"
                  [disabled]="!contentMutable"
                  (valueChange)="invokeValueChange()"
                  [valueRequired]="true"
                  [validateOnTouch]="true"
                  [integer]="true"
                  [error]="'error.formel.allgemein.lohnhersteller.plz.blank'">
                </app-bew-input>

                <app-bew-input
                  #allgemeinLohnherstellerOrt
                  #allgemeinLohnherstellerItem
                  [label]="
                    'formel.detail.allgemein.lohnhersteller.ort' | translate
                  "
                  [(value)]="formel.allgemein.lohnhersteller.ort"
                  [maxlength]="150"
                  [disabled]="!contentMutable"
                  (valueChange)="invokeValueChange()"
                  [valueRequired]="true"
                  [validateOnTouch]="true"
                  [error]="'error.formel.allgemein.lohnhersteller.ort.blank'">
                </app-bew-input>
              </div>
            </app-bew-box>

            <app-default-file-filter #fileFilter></app-default-file-filter>
            <app-multi-file-upload
              [fileFilter]="fileFilter.fileFilter"
              [label]="'multiFileUploadComponent.title' | translate"
              [noFilesText]="'formel.detail.allgemein.file.info' | translate"
              [withFilesText]="'formel.detail.allgemein.file.info' | translate"
              [bewFiles]="formel.allgemein.files"
              [disabled]="!contentMutable"
              (updateFiles)="updateAllgemeinFiles($event)">
            </app-multi-file-upload>

            <app-bew-textarea
              [label]="'formel.detail.allgemein.notes' | translate"
              [(value)]="formel.allgemein.notes"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>

            <div class="pruefung-notes-toggle-button-container">
              <button
                *ngIf="!isDraft"
                class="efo-button blue"
                (click)="toggleFormelWide()"
                [ngbTooltip]="tooltipTogglePruefungsnotes"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-note-sticky"></i>
              </button>
            </div>
          </app-bew-box>
        </div>
        <div
          [ngClass]="isFormelWide ? 'col-0' : 'col-3'"
          class="pruefung-notes-container-parent">
          <div
            *ngIf="!isDraft && !isFormelWide"
            class="pruefung-notes-container">
            <app-bew-textarea
              *ngIf="!isDraft && !isFormelWide"
              style="height: max-content !important"
              [label]="'formel.detail.allgemein.notes' | translate"
              [(value)]="formel.pruefungsNotes.notesAllgemein"
              [maxlength]="4000"
              [disabled]="!padNotesMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div [ngClass]="isFormelWide ? 'col-12' : 'col-9'">
          <app-bew-box
            class="main-box"
            [label]="'formel.detail.zusammensetzung.title' | translate">
            <div
              [innerHTML]="
                'formel.detail.zusammensetzung.title' | translate
              "></div>

            <app-bew-input
              #zusammensetzungChargengroesse
              #zusammensetzungItem
              [label]="
                'formel.detail.zusammensetzung.chargengroesse.title' | translate
              "
              placement="top"
              [ngbTooltip]="tooltipZusammensetzungChargengroesse"
              [(value)]="formel.zusammensetzung.chargengroesse"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [integer]="true"
              [maxlength]="9"
              [error]="'error.formel.zusammensetzung.chargengroesse.null'">
            </app-bew-input>

            <app-bew-select
              #zusammensetzungChargengroesseEinheit
              #zusammensetzungItem
              [disabled]="!contentMutable"
              [(value)]="formel.zusammensetzung.chargengroesseEinheit"
              [items]="
                Object.values(ZusammensetzungDraftDTO.ChargengroesseEinheitEnum)
              "
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="
                'error.formel.zusammensetzung.chargengroesseeinheit.null'
              "
              [prefix]="'enum.chargengroesseeinheit.'"
              [label]="'formel.detail.zusammensetzung.masseinheit' | translate">
            </app-bew-select>

            <app-bew-box
              [label]="
                'formel.detail.zusammensetzung.komponente.new.title' | translate
              ">
              <app-bew-input
                #zusammensetzungKomponentBezeichnung
                #zusammensetzungKomponentItem
                class="validation-komponent"
                [label]="
                  'formel.detail.zusammensetzung.bezeichnung.title' | translate
                "
                placement="top"
                [ngbTooltip]="tooltipZusammensetzungBezeichnung"
                [(value)]="formel.zusammensetzung.komponent.bezeichnung"
                (valueChange)="searchKomponentenSuggestions()"
                [maxlength]="250"
                [disabled]="!contentMutable"
                [valueRequired]="true"
                [results]="komponentSUggestions"
                (selectResult)="selectKomponentSuggestion($event)"
                [error]="
                  'error.formel.zusammensetzung.komponente.bezeichnung.blank'
                ">
              </app-bew-input>

              <app-bew-select
                #zusammensetzungKomponentQualitaet
                #zusammensetzungKomponentItem
                placement="top"
                [ngbTooltip]="tooltipZusammensetzungQualitaet"
                class="validation-komponent"
                [disabled]="!contentMutable"
                [(value)]="formel.zusammensetzung.komponent.qualitaet"
                [items]="Object.values(KompenenteDraftDTO.QualitaetEnum)"
                [valueRequired]="true"
                (valueChange)="validateKomponentBemerkung()"
                [error]="
                  'error.formel.zusammensetzung.komponente.qualitaet.null'
                "
                [prefix]="'enum.qualitaet.'"
                [label]="
                  'formel.detail.zusammensetzung.qualitaet.title' | translate
                ">
              </app-bew-select>

              <app-bew-select
                #zusammensetzungKomponentArt
                #zusammensetzungKomponentItem
                placement="top"
                [ngbTooltip]="tooltipZusammensetzungArt"
                class="validation-komponent"
                [disabled]="!contentMutable"
                [(value)]="formel.zusammensetzung.komponent.art"
                [items]="Object.values(KompenenteDraftDTO.ArtEnum)"
                [valueRequired]="true"
                [error]="'error.formel.zusammensetzung.komponente.art.null'"
                [prefix]="'enum.komponenteart.'"
                [label]="'formel.detail.zusammensetzung.art.title' | translate">
              </app-bew-select>

              <app-bew-input
                #zusammensetzungKomponentEinheitsmenge
                #zusammensetzungKomponentItem
                class="validation-komponent"
                [label]="
                  'formel.detail.zusammensetzung.einheitsmenge.title'
                    | translate
                "
                placement="top"
                [ngbTooltip]="tooltipZusammensetzungEinheitsmenge"
                [(value)]="formel.zusammensetzung.komponent.einheitsMenge"
                [maxlength]="9"
                [disabled]="!contentMutable"
                [valueRequired]="true"
                [decimal]="true"
                [error]="
                  'error.formel.zusammensetzung.komponente.einheitsmenge.blank'
                ">
              </app-bew-input>

              <app-bew-select
                #zusammensetzungKomponentEinheit
                #zusammensetzungKomponentItem
                class="validation-komponent"
                [disabled]="!contentMutable"
                [(value)]="formel.zusammensetzung.komponent.einheit"
                [items]="Object.values(KompenenteDraftDTO.EinheitEnum)"
                [valueRequired]="true"
                [error]="
                  'error.formel.zusammensetzung.komponente.einheit.blank'
                "
                [prefix]="'enum.komponenteeinheit.'"
                [label]="'formel.detail.zusammensetzung.einheit' | translate">
              </app-bew-select>

              <app-bew-input
                #zusammensetzungKomponentBemerkung
                #zusammensetzungKomponentItem
                class="validation-komponent"
                [label]="'formel.detail.zusammensetzung.bemerkung' | translate"
                [(value)]="formel.zusammensetzung.komponent.bemerkung"
                [maxlength]="250"
                [disabled]="!contentMutable"
                [error]="
                  'error.formel.zusammensetzung.komponente.bemerkung.blank'
                ">
              </app-bew-input>

              <div
                *ngIf="
                  contentMutable &&
                  formel.zusammensetzung.komponent.qualitaet == 'ANDERE' &&
                  formel.zusammensetzung.komponent.art ==
                    'WIRKSTOFF_DEKLARATIONSPFLICHTIG'
                "
                [innerHTML]="
                  'formel.detail.zusammensetzung.komponenten.wirkstoff_andere_info'
                    | translate
                "
                class="efo-info-box"></div>

              <div
                *ngIf="
                  contentMutable &&
                  formel.zusammensetzung.komponent.qualitaet == 'ANDERE' &&
                  (formel.zusammensetzung.komponent.art ==
                    'HILFSSTOFF_WEITERER' ||
                    formel.zusammensetzung.komponent.art ==
                      'HILFSSTOFF_DEKLARATIONSPFLICHTIG')
                "
                [innerHTML]="
                  'formel.detail.zusammensetzung.komponenten.hilfsstoff_andere_info'
                    | translate
                "
                class="efo-info-box"></div>

              <div *ngIf="mutable()">
                <button
                  class="efo-button blue"
                  (click)="addKomponente()"
                  [disabled]="!contentMutable">
                  {{
                    'formel.detail.zusammensetzung.komponente.new.submit'
                      | translate
                  }}

                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </app-bew-box>

            <div
              [innerHTML]="
                'formel.detail.zusammensetzung.komponenten.title' | translate
              "></div>

            <div class="bew-table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {{
                        'formel.detail.zusammensetzung.bezeichnung.title'
                          | translate
                      }}
                    </th>
                    <th>
                      {{
                        'formel.detail.zusammensetzung.qualitaet.title'
                          | translate
                      }}
                    </th>
                    <th>
                      {{
                        'formel.detail.zusammensetzung.art.title' | translate
                      }}
                    </th>
                    <th>
                      {{
                        'formel.detail.zusammensetzung.einheitsmenge.title'
                          | translate
                      }}
                    </th>
                    <th>
                      {{ 'formel.detail.zusammensetzung.einheit' | translate }}
                    </th>
                    <th>
                      {{
                        'formel.detail.zusammensetzung.bemerkung' | translate
                      }}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                  <tr *ngIf="formel.zusammensetzung.komponenten.size == 0">
                    <td colspan="100">
                      {{
                        'formel.detail.zusammensetzung.komponenten.none'
                          | translate
                      }}
                    </td>
                  </tr>
                  <ng-container
                    *ngFor="
                      let komponente of formel.zusammensetzung.komponenten.toArray();
                      index as i
                    ">
                    <tr
                      class="efo-drag-drop-element"
                      cdkDrag
                      cdkDragLockAxis="y"
                      [cdkDragDisabled]="!contentMutable">
                      <td cdkDragHandle>{{ komponente.komponentOrder }}</td>
                      <td cdkDragHandle>{{ komponente.bezeichnung }}</td>
                      <td cdkDragHandle>
                        <div *ngIf="komponente.qualitaet">
                          {{
                            'enum.qualitaet.' + komponente.qualitaet | translate
                          }}
                        </div>
                      </td>
                      <td cdkDragHandle>
                        <div *ngIf="komponente.art">
                          {{
                            'enum.komponenteart.' + komponente.art | translate
                          }}
                        </div>
                      </td>
                      <td cdkDragHandle>{{ komponente.einheitsMenge }}</td>
                      <td cdkDragHandle>
                        <div *ngIf="komponente.einheit">
                          {{
                            'enum.komponenteeinheit.' + komponente.einheit
                              | translate
                          }}
                        </div>
                      </td>
                      <td cdkDragHandle>{{ komponente.bemerkung }}</td>
                      <td>
                        <button
                          class="efo-button blue"
                          (click)="toggleKomponentEdit(i, komponente)"
                          [disabled]="!contentMutable"
                          [ngbTooltip]="tooltipEdit"
                          triggers="hover"
                          [openDelay]="500"
                          [closeDelay]="300">
                          <i class="fa fa-edit"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          class="efo-button red"
                          (click)="deleteKomponente(i)"
                          [disabled]="!contentMutable"
                          [ngbTooltip]="tooltipDelete"
                          triggers="hover"
                          [openDelay]="500"
                          [closeDelay]="300">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>

                      <!-- Preview -->
                      <div
                        *cdkDragPreview
                        matchSize="true"
                        class="container dragdrop-placeholder">
                        {{ komponente.bezeichnung }}
                      </div>
                    </tr>
                    <tr *ngIf="toggledKomponentEdit === i">
                      <td colspan="100">
                        <app-bew-box
                          [label]="
                            'formel.detail.zusammensetzung.komponente.edit'
                              | translate
                          ">
                          <app-bew-input
                            #zusammensetzungKomponentBezeichnungEdit
                            #zusammensetzungKomponentItemEdit
                            class="validation-komponent"
                            [label]="
                              'formel.detail.zusammensetzung.bezeichnung.title'
                                | translate
                            "
                            placement="bottom"
                            [ngbTooltip]="tooltipZusammensetzungBezeichnung"
                            [(value)]="editedKomponente.bezeichnung"
                            [disabled]="!contentMutable"
                            [valueRequired]="true"
                            [results]="komponentSuggestionsEdit"
                            (valueChange)="searchKomponentenSuggestionsEdit()"
                            [maxlength]="250"
                            (selectResult)="
                              selectKomponentSuggestionEdit($event)
                            "
                            [error]="
                              'error.formel.zusammensetzung.komponente.bezeichnung.blank'
                            ">
                          </app-bew-input>

                          <app-bew-select
                            #zusammensetzungKomponentQualitaetEdit
                            #zusammensetzungKomponentItemEdit
                            placement="top"
                            [ngbTooltip]="tooltipZusammensetzungQualitaet"
                            class="validation-komponent"
                            [disabled]="!contentMutable"
                            [(value)]="editedKomponente.qualitaet"
                            [items]="
                              Object.values(KompenenteDraftDTO.QualitaetEnum)
                            "
                            [valueRequired]="true"
                            (valueChange)="validateKomponentBemerkungEdit()"
                            [error]="
                              'error.formel.zusammensetzung.komponente.qualitaet.null'
                            "
                            [prefix]="'enum.qualitaet.'"
                            [label]="
                              'formel.detail.zusammensetzung.qualitaet.title'
                                | translate
                            ">
                          </app-bew-select>

                          <app-bew-select
                            #zusammensetzungKomponentArtEdit
                            #zusammensetzungKomponentItemEdit
                            placement="bottom"
                            [ngbTooltip]="tooltipZusammensetzungArt"
                            class="validation-komponent"
                            [disabled]="!contentMutable"
                            [(value)]="editedKomponente.art"
                            [items]="Object.values(KompenenteDraftDTO.ArtEnum)"
                            [valueRequired]="true"
                            [error]="
                              'error.formel.zusammensetzung.komponente.art.null'
                            "
                            [prefix]="'enum.komponenteart.'"
                            [label]="
                              'formel.detail.zusammensetzung.art.title'
                                | translate
                            ">
                          </app-bew-select>

                          <app-bew-input
                            #zusammensetzungKomponentEinheitsmengeEdit
                            #zusammensetzungKomponentItemEdit
                            class="validation-komponent"
                            [label]="
                              'formel.detail.zusammensetzung.einheitsmenge.title'
                                | translate
                            "
                            placement="top"
                            [ngbTooltip]="tooltipZusammensetzungEinheitsmenge"
                            [(value)]="editedKomponente.einheitsMenge"
                            [maxlength]="9"
                            [disabled]="!contentMutable"
                            [valueRequired]="true"
                            [decimal]="true"
                            [error]="
                              'error.formel.zusammensetzung.komponente.einheitsmenge.blank'
                            ">
                          </app-bew-input>

                          <app-bew-select
                            #zusammensetzungKomponentEinheitEdit
                            #zusammensetzungKomponentItemEdit
                            class="validation-komponent"
                            [disabled]="!contentMutable"
                            [(value)]="editedKomponente.einheit"
                            [items]="
                              Object.values(KompenenteDraftDTO.EinheitEnum)
                            "
                            [valueRequired]="true"
                            [error]="
                              'error.formel.zusammensetzung.komponente.einheit.blank'
                            "
                            [prefix]="'enum.komponenteeinheit.'"
                            [label]="
                              'formel.detail.zusammensetzung.einheit'
                                | translate
                            ">
                          </app-bew-select>

                          <app-bew-input
                            #zusammensetzungKomponentBemerkungEdit
                            #zusammensetzungKomponentItemEdit
                            class="validation-komponent"
                            [label]="
                              'formel.detail.zusammensetzung.bemerkung'
                                | translate
                            "
                            [(value)]="editedKomponente.bemerkung"
                            [maxlength]="250"
                            [disabled]="!contentMutable"
                            [error]="
                              'error.formel.zusammensetzung.komponente.bemerkung.blank'
                            ">
                          </app-bew-input>

                          <div
                            *ngIf="
                              contentMutable &&
                              editedKomponente.qualitaet == 'ANDERE' &&
                              editedKomponente.art ==
                                'WIRKSTOFF_DEKLARATIONSPFLICHTIG'
                            "
                            [innerHTML]="
                              'formel.detail.zusammensetzung.komponenten.wirkstoff_andere_info'
                                | translate
                            "
                            class="efo-info-box"></div>

                          <div
                            *ngIf="
                              contentMutable &&
                              editedKomponente.qualitaet == 'ANDERE' &&
                              (editedKomponente.art == 'HILFSSTOFF_WEITERER' ||
                                editedKomponente.art ==
                                  'HILFSSTOFF_DEKLARATIONSPFLICHTIG')
                            "
                            [innerHTML]="
                              'formel.detail.zusammensetzung.komponenten.hilfsstoff_andere_info'
                                | translate
                            "
                            class="efo-info-box"></div>

                          <div *ngIf="mutable()">
                            <button
                              class="efo-button blue"
                              (click)="updateKomponente(komponente)"
                              [disabled]="!contentMutable">
                              {{
                                'formel.detail.zusammensetzung.komponente.edit.submit'
                                  | translate
                              }}
                            </button>

                            <button
                              class="efo-button red"
                              (click)="cancelKomponentEdit()"
                              [disabled]="!contentMutable">
                              {{
                                'formel.detail.zusammensetzung.komponente.edit.cancel'
                                  | translate
                              }}
                            </button>
                          </div>
                        </app-bew-box>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div *ngIf="contentMutable" class="efo-info-box">
              {{ 'formel.detail.zusammensetzung.komponenten.info' | translate }}
            </div>

            <app-multi-file-upload
              [fileFilter]="fileFilter.fileFilter"
              [label]="'multiFileUploadComponent.title' | translate"
              [noFilesText]="
                'formel.detail.zusammensetzung.file.info' | translate
              "
              [withFilesText]="
                'formel.detail.zusammensetzung.file.info' | translate
              "
              [bewFiles]="this.formel.zusammensetzung.files"
              [disabled]="!contentMutable"
              (updateFiles)="updateZusammensetzungFiles($event)">
            </app-multi-file-upload>

            <app-bew-textarea
              [label]="'formel.detail.zusammensetzung.notes' | translate"
              [(value)]="formel.zusammensetzung.notes"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>

            <div class="pruefung-notes-toggle-button-container">
              <button
                *ngIf="!isDraft"
                class="efo-button blue"
                (click)="toggleFormelWide()"
                [ngbTooltip]="tooltipTogglePruefungsnotes"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-note-sticky"></i>
              </button>
            </div>
          </app-bew-box>
        </div>
        <div
          [ngClass]="isFormelWide ? 'col-0' : 'col-3'"
          class="pruefung-notes-container-parent">
          <div
            *ngIf="!isDraft && !isFormelWide"
            class="pruefung-notes-container">
            <app-bew-textarea
              *ngIf="!isDraft && !isFormelWide"
              style="height: max-content !important"
              [label]="'formel.detail.zusammensetzung.notes' | translate"
              [(value)]="formel.pruefungsNotes.notesZusammensetzung"
              [maxlength]="4000"
              [disabled]="!padNotesMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div [ngClass]="isFormelWide ? 'col-12' : 'col-9'">
          <app-bew-box
            class="main-box"
            [label]="'formel.detail.lagerung.title' | translate">
            <app-bew-box
              [label]="
                'formel.detail.lagerung.lagerungshinweise.title' | translate
              ">
              <div>
                <div
                  class="row"
                  *ngFor="
                    let lagerungshinweis of Object.values(
                      LagerungDraftDTO.LagerungshinweiseEnum
                    )
                  ">
                  <input
                    class="col-1"
                    type="checkbox"
                    [ngModel]="isLagerungshinweisSelected(lagerungshinweis)"
                    (click)="toggleLagerungshinweis(lagerungshinweis)"
                    [disabled]="!mutable()" />
                  <div
                    placement="top"
                    [ngbTooltip]="tooltipLagerungLagerungshinweise"
                    class="col-11">
                    {{
                      'enum.lagerungshinweis.' + lagerungshinweis | translate
                    }}
                  </div>
                </div>
              </div>
            </app-bew-box>
            <app-bew-input
              #lagerungItem
              [label]="'formel.detail.lagerung.bemerkung' | translate"
              [(value)]="formel.lagerung.bemerkung"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-input>

            <app-bew-input
              #lagerungHaltbarkeitsdauer
              #lagerungItem
              placement="top"
              [ngbTooltip]="tooltipLagerungHaltbarkeitsdauer"
              [label]="
                'formel.detail.lagerung.haltbarkeitsdauer.title' | translate
              "
              [(value)]="formel.lagerung.haltbarkeitsDauer"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()"
              [valueRequired]="true"
              [validateOnTouch]="true"
              [error]="'error.formel.lagerung.haltbarkeitsdauer.blank'">
            </app-bew-input>

            <div
              *ngIf="contentMutable"
              [innerHTML]="
                'formel.detail.lagerung.haltbarkeitsdauer.info' | translate
              "
              class="efo-info-box"></div>

            <app-multi-file-upload
              [fileFilter]="fileFilter.fileFilter"
              [label]="'multiFileUploadComponent.title' | translate"
              [noFilesText]="'formel.detail.lagerung.file.info' | translate"
              [withFilesText]="'formel.detail.lagerung.file.info' | translate"
              [bewFiles]="this.formel.lagerung.files"
              [disabled]="!contentMutable"
              (updateFiles)="updatelagerungFiles($event)">
            </app-multi-file-upload>

            <app-bew-textarea
              [label]="'formel.detail.lagerung.notes' | translate"
              [(value)]="formel.lagerung.notes"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>

            <div class="pruefung-notes-toggle-button-container">
              <button
                *ngIf="!isDraft"
                class="efo-button blue"
                (click)="toggleFormelWide()"
                [ngbTooltip]="tooltipTogglePruefungsnotes"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-note-sticky"></i>
              </button>
            </div>
          </app-bew-box>
        </div>
        <div
          [ngClass]="isFormelWide ? 'col-0' : 'col-3'"
          class="pruefung-notes-container-parent">
          <div
            *ngIf="!isDraft && !isFormelWide"
            class="pruefung-notes-container">
            <app-bew-textarea
              *ngIf="!isDraft && !isFormelWide"
              style="height: max-content !important"
              [label]="'formel.detail.lagerung.notes' | translate"
              [(value)]="formel.pruefungsNotes.notesLagerung"
              [maxlength]="4000"
              [disabled]="!padNotesMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div [ngClass]="isFormelWide ? 'col-12' : 'col-9'">
          <app-bew-box
            class="main-box"
            [label]="'formel.detail.verpackung.title' | translate">
            <div
              [innerHTML]="
                'formel.detail.verpackung.behaeltergroesse.title' | translate
              "></div>
            <app-bew-box
              [label]="
                'formel.detail.verpackung.behaeltergroesse.new.title'
                  | translate
              ">
              <app-bew-input
                #verpackungPackungsinhalt
                #verpackungBehaeltergroesseItem
                [label]="
                  'formel.detail.verpackung.behaeltergroesse.packungsinhalt.title'
                    | translate
                "
                placement="top"
                [ngbTooltip]="tooltipVerpackungPackungsinhalt"
                [(value)]="formel.verpackung.behealterGroesse.packungsinhalt"
                [disabled]="!contentMutable"
                [valueRequired]="true"
                [type]="'number'"
                [error]="'error.formel.verpackung.packungsinhalt.blank'">
              </app-bew-input>

              <app-bew-select
                #verpackungBehealterGroesseEinheit
                #verpackungBehaeltergroesseItem
                [disabled]="!contentMutable"
                [(value)]="formel.verpackung.behealterGroesse.einheit"
                [items]="Object.values(BehaelterGroesseDraftDTO.EinheitEnum)"
                [valueRequired]="true"
                [error]="'error.formel.verpackung.einheit.null'"
                [prefix]="'enum.behaeltergroesseeinheit.'"
                [label]="
                  'formel.detail.verpackung.behaeltergroesse.einheit'
                    | translate
                ">
              </app-bew-select>

              <app-bew-input
                #verpackungArt
                #verpackungBehaeltergroesseItem
                [label]="
                  'formel.detail.verpackung.behaeltergroesse.art.title'
                    | translate
                "
                [(value)]="
                  formel.verpackung.behealterGroesse.artMaterialBehaelter
                "
                placement="top"
                [ngbTooltip]="tooltipVerpackungBehaeltergroesseArt"
                [disabled]="!contentMutable"
                [valueRequired]="true"
                [error]="'error.formel.verpackung.artmaterialbehaelter.blank'">
              </app-bew-input>

              <app-bew-input
                #verpackungBemerkung
                #verpackungBehaeltergroesseItem
                [label]="
                  'formel.detail.verpackung.behaeltergroesse.bemerkung'
                    | translate
                "
                [(value)]="formel.verpackung.behealterGroesse.bemerkung"
                [disabled]="!contentMutable"
                [valueRequired]="false">
              </app-bew-input>

              <div *ngIf="mutable()">
                <button
                  class="efo-button blue"
                  (click)="addBehaelterGroesse()"
                  [disabled]="!contentMutable">
                  {{
                    'formel.detail.verpackung.behaeltergroesse.new.submit'
                      | translate
                  }}
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </app-bew-box>

            <div class="bew-table-container">
              <table>
                <tbody>
                  <tr>
                    <th>
                      {{
                        'formel.detail.verpackung.behaeltergroesse.packungsinhalt.title'
                          | translate
                      }}
                    </th>
                    <th>
                      {{
                        'formel.detail.verpackung.behaeltergroesse.einheit'
                          | translate
                      }}
                    </th>
                    <th>
                      {{
                        'formel.detail.verpackung.behaeltergroesse.bemerkung'
                          | translate
                      }}
                    </th>
                    <th>
                      {{
                        'formel.detail.verpackung.behaeltergroesse.art.title'
                          | translate
                      }}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                  <tr *ngIf="formel.verpackung.behaelterGroessen.size == 0">
                    <td colspan="100">
                      {{
                        'formel.detail.verpackung.behaltergroessen.none'
                          | translate
                      }}
                    </td>
                  </tr>
                  <ng-container
                    *ngFor="
                      let behalterGroesse of formel.verpackung.behaelterGroessen.toArray();
                      index as i
                    ">
                    <tr>
                      <td>{{ behalterGroesse.packungsinhalt }}</td>
                      <td>
                        {{
                          'enum.behaeltergroesseeinheit.' +
                            behalterGroesse.einheit | translate
                        }}
                      </td>
                      <td>{{ behalterGroesse.bemerkung }}</td>
                      <td>{{ behalterGroesse.artMaterialBehaelter }}</td>
                      <td>
                        <button
                          class="efo-button blue"
                          (click)="
                            toggleBehaelterGroesseEdit(i, behalterGroesse)
                          "
                          [disabled]="!contentMutable"
                          [ngbTooltip]="tooltipEdit"
                          triggers="hover"
                          [openDelay]="500"
                          [closeDelay]="300">
                          <i class="fa fa-edit"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          class="efo-button red"
                          (click)="deleteBehaelterGroesse(i)"
                          [disabled]="!contentMutable"
                          [ngbTooltip]="tooltipDelete"
                          triggers="hover"
                          [openDelay]="500"
                          [closeDelay]="300">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tr *ngIf="toggledBehaelterGroesseEdit === i">
                      <td colspan="100">
                        <app-bew-box
                          [label]="
                            'formel.detail.zusammensetzung.komponente.edit'
                              | translate
                          ">
                          <app-bew-input
                            #verpackungPackungsinhaltEdit
                            #verpackungBehaeltergroesseItemEdit
                            [label]="
                              'formel.detail.verpackung.behaeltergroesse.packungsinhalt.title'
                                | translate
                            "
                            placement="top"
                            [ngbTooltip]="tooltipVerpackungPackungsinhalt"
                            [(value)]="editedBehalterGroesse.packungsinhalt"
                            [disabled]="!contentMutable"
                            [valueRequired]="true"
                            [type]="'number'"
                            [error]="
                              'error.formel.verpackung.packungsinhalt.blank'
                            ">
                          </app-bew-input>

                          <app-bew-select
                            #verpackungBehealterGroesseEinheitEdit
                            #verpackungBehaeltergroesseItemEdit
                            [disabled]="!contentMutable"
                            [(value)]="editedBehalterGroesse.einheit"
                            [items]="
                              Object.values(
                                BehaelterGroesseDraftDTO.EinheitEnum
                              )
                            "
                            [valueRequired]="true"
                            [error]="'error.formel.verpackung.einheit.null'"
                            [prefix]="'enum.behaeltergroesseeinheit.'"
                            [label]="
                              'formel.detail.verpackung.behaeltergroesse.einheit'
                                | translate
                            ">
                          </app-bew-select>

                          <app-bew-input
                            #verpackungArtEdit
                            #verpackungBehaeltergroesseItemEdit
                            [label]="
                              'formel.detail.verpackung.behaeltergroesse.art.title'
                                | translate
                            "
                            [(value)]="
                              editedBehalterGroesse.artMaterialBehaelter
                            "
                            [disabled]="!contentMutable"
                            [valueRequired]="true"
                            [error]="
                              'error.formel.verpackung.artmaterialbehaelter.blank'
                            ">
                          </app-bew-input>

                          <app-bew-input
                            #verpackungBemerkungEdit
                            #verpackungBehaeltergroesseItemEdit
                            [label]="
                              'formel.detail.verpackung.behaeltergroesse.bemerkung'
                                | translate
                            "
                            [(value)]="editedBehalterGroesse.bemerkung"
                            [disabled]="!contentMutable"
                            [valueRequired]="false">
                          </app-bew-input>

                          <div *ngIf="mutable()">
                            <button
                              class="efo-button blue"
                              (click)="updateBehaelterGroesse(behalterGroesse)"
                              [disabled]="!contentMutable">
                              {{
                                'formel.detail.verpackung.behaeltergroesse.edit.submit'
                                  | translate
                              }}
                            </button>

                            <button
                              class="efo-button red"
                              (click)="cancelBehaelterGroesseEdit()"
                              [disabled]="!contentMutable">
                              {{
                                'formel.detail.verpackung.behaeltergroesse.edit.cancel'
                                  | translate
                              }}
                            </button>
                          </div>
                        </app-bew-box>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <app-multi-file-upload
              [fileFilter]="fileFilter.fileFilter"
              [label]="'multiFileUploadComponent.title' | translate"
              [noFilesText]="'formel.detail.verpackung.file.info' | translate"
              [withFilesText]="'formel.detail.verpackung.file.info' | translate"
              [bewFiles]="formel.verpackung.files"
              [disabled]="!contentMutable"
              (updateFiles)="updateVerpackungFiles($event)">
            </app-multi-file-upload>

            <app-bew-textarea
              [label]="'formel.detail.verpackung.notes' | translate"
              [(value)]="formel.verpackung.notes"
              [disabled]="!contentMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>

            <div style="margin-top: 15px">
              {{ 'formel.detail.verpackung.etiketten.title' | translate }}
            </div>

            <app-multi-file-upload
              placement="top"
              [ngbTooltip]="tooltipVerpackungEtiketten"
              [label]="
                'formel.detail.verpackung.etiketten.upload.title' | translate
              "
              [noFilesText]="
                'formel.detail.verpackung.etiketten.upload.noEtikettenYet'
                  | translate
              "
              [withFilesText]="
                'formel.detail.verpackung.etiketten.upload.withnoEtikettenText'
                  | translate
              "
              [bewFiles]="formel.verpackung.etiketten"
              [disabled]="!contentMutable"
              (updateFiles)="updateEtiketten($event)">
            </app-multi-file-upload>

            <div
              *ngIf="!etikettenValid"
              [innerHTML]="
                'error.formel.verpackung.etiketten.empty' | translate
              "
              class="efo-error-box"></div>

            <div class="pruefung-notes-toggle-button-container">
              <button
                *ngIf="!isDraft"
                class="efo-button blue"
                (click)="toggleFormelWide()"
                [ngbTooltip]="tooltipTogglePruefungsnotes"
                triggers="hover"
                [openDelay]="500"
                [closeDelay]="300">
                <i class="fa fa-note-sticky"></i>
              </button>
            </div>
          </app-bew-box>
        </div>
        <div
          [ngClass]="isFormelWide ? 'col-0' : 'col-3'"
          class="pruefung-notes-container-parent">
          <div
            *ngIf="!isDraft && !isFormelWide"
            class="pruefung-notes-container">
            <app-bew-textarea
              *ngIf="!isDraft && !isFormelWide"
              style="height: max-content !important"
              [label]="'formel.detail.allgemein.notes' | translate"
              [(value)]="formel.pruefungsNotes.notesVerpackung"
              [maxlength]="4000"
              [disabled]="!padNotesMutable"
              (valueChange)="invokeValueChange()">
            </app-bew-textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="isFormelWide ? 'col-12' : 'col-9'">
          <app-bew-box
            class="main-box"
            [label]="'formel.detail.risikorechner.title' | translate">
            <div style="font-weight: bolder; margin-bottom: 5px">
              {{ 'formel.detail.risikorechner.info.title' | translate }}
            </div>
            <div>
              {{ 'formel.detail.risikorechner.info.text' | translate }}
            </div>
            <div class="resultatblock">
              <div
                class="unter-100 col extra-padding"
                style="display: inline-block"
                *ngIf="this.risikoFaktor < 100"
                [innerHTML]="getRisikofaktorResultText()"></div>
              <div
                class="ueber-100 col extra-padding"
                style="display: inline-block"
                *ngIf="this.risikoFaktor >= 100"
                [innerHTML]="getRisikofaktorResultText()"></div>
            </div>

            <form id="riskForm" #riskForm="ngForm">
              <div class="row riskorechner_inputblock">
                <div class="col-12 bew-form-group">
                  <label for="risk_praeparatArt"
                    >{{
                      'formel.detail.risikorechner.form.anwendungart'
                        | translate
                    }}
                    *</label
                  >
                  <select
                    id="risk_praeparatArt"
                    name="risk_praeparatArt"
                    class="form-control"
                    [(ngModel)]="formelRisikoParameterSelection.anwendungsart"
                    [disabled]="!contentMutable"
                    (change)="resetSelection()"
                    required>
                    <option></option>
                    <option
                      *ngFor="let element of Object.values(Anwendungsart)"
                      value="{{ element }}">
                      {{
                        'enum.anwendungsart.' + JSON.parse(element).name
                          | translate
                      }}
                      ({{ JSON.parse(element).riskFactor }})
                    </option>
                  </select>
                </div>
              </div>

              <div class="row riskorechner_inputblock">
                <div class="col-12 bew-form-group">
                  <label for="risk_mengenverhaeltnis"
                    >{{
                      'formel.detail.risikorechner.form.mengenverhaeltnis'
                        | translate
                    }}
                    *
                  </label>
                  <select
                    id="risk_mengenverhaeltnis"
                    name="risk_mengenverhaeltnis"
                    class="form-control"
                    [(ngModel)]="
                      formelRisikoParameterSelection.mengenverhaeltnis
                    "
                    [disabled]="!contentMutable"
                    (change)="resetSelection()"
                    required>
                    <option></option>
                    <option
                      *ngFor="let element of Object.values(Mengenverhaeltnis)"
                      value="{{ element }}">
                      {{
                        'enum.mengenverhaeltnis.' + JSON.parse(element).name
                          | translate
                      }}
                      ({{ JSON.parse(element).riskFactor }})
                    </option>
                  </select>
                </div>
              </div>

              <div class="row riskorechner_inputblock">
                <div class="col-12 bew-form-group">
                  <label for="risk_wirkstoffrisiko"
                    >{{
                      'formel.detail.risikorechner.form.wirkstoffrisiko'
                        | translate
                    }}
                    *
                  </label>
                  <select
                    id="risk_wirkstoffrisiko"
                    name="risk_wirkstoffrisiko"
                    class="form-control"
                    [(ngModel)]="formelRisikoParameterSelection.wirkstoffrisiko"
                    [disabled]="!contentMutable"
                    (change)="resetSelection()"
                    required>
                    <option></option>
                    <option
                      *ngFor="let element of Object.values(Wirkstoffrisiko)"
                      value="{{ element }}">
                      {{
                        'enum.wirkstoffrisiko.' + JSON.parse(element).name
                          | translate
                      }}
                      ({{ JSON.parse(element).riskFactor }})
                    </option>
                  </select>
                </div>
              </div>

              <div class="row riskorechner_inputblock">
                <div class="col-12 bew-form-group">
                  <label for="risk_herstellungprozess"
                    >{{
                      'formel.detail.risikorechner.form.herstellungsprozess'
                        | translate
                    }}
                    *
                  </label>
                  <select
                    id="risk_herstellungprozess"
                    name="risk_herstellungprozess"
                    class="form-control"
                    [(ngModel)]="
                      formelRisikoParameterSelection.herstellungsprozess
                    "
                    [disabled]="!contentMutable"
                    (change)="resetSelection()"
                    required>
                    <option></option>
                    <option
                      *ngFor="let element of Object.values(Herstellungsprozess)"
                      value="{{ element }}">
                      {{
                        'enum.herstellungsprozess.' + JSON.parse(element).name
                          | translate
                      }}
                      ({{ JSON.parse(element).riskFactor }})
                    </option>
                  </select>
                </div>
              </div>

              <div class="row riskorechner_inputblock">
                <div class="col-12 bew-form-group">
                  <label for="risk_arzneiform">
                    {{
                      'formel.detail.risikorechner.form.arzneiform' | translate
                    }}
                    *
                  </label>
                  <select
                    id="risk_arzneiform"
                    name="risk_arzneiform"
                    class="form-control"
                    [(ngModel)]="formelRisikoParameterSelection.arzneiform"
                    [disabled]="!contentMutable"
                    (change)="arzneiFormChanged()"
                    required>
                    <option></option>
                    <option
                      *ngFor="let element of Object.values(Arzneiform)"
                      value="{{ element }}">
                      {{
                        'enum.arzneiform.' + JSON.parse(element).name
                          | translate
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row riskorechner_inputblock">
                <div class="col-12 bew-form-group">
                  <label for="risk_arzneiform"
                    >{{ getProduktionsmengenLabelKey() | translate }} *
                  </label>
                  <select
                    id="risk_produktionsmenge"
                    name="risk_produktionsmenge"
                    class="form-control"
                    [(ngModel)]="
                      formelRisikoParameterSelection.produktionsmenge
                    "
                    [disabled]="!contentMutable"
                    (change)="resetSelection()"
                    required>
                    <option></option>
                    <option
                      *ngFor="let element of produktionsmenge"
                      value="{{ element }}">
                      {{
                        'enum.produktionsmenge.' + JSON.parse(element).name
                          | translate
                      }}
                      ({{ JSON.parse(element).riskFactor }})
                    </option>
                  </select>
                </div>
              </div>

              <button
                class="efo-button blue"
                [disabled]="!riskForm.valid"
                (click)="formelRisikoBerechnen()">
                {{ 'formel.detail.risikorechner.button' | translate }}
              </button>
            </form>
          </app-bew-box>
        </div>
      </div>

      <div class="row">
        <div [ngClass]="isFormelWide ? 'col-12' : 'col-9'">
          <app-bew-box
            *ngIf="padNotesMutable"
            [label]="'formel.detail.abschliessen.title' | translate">
            <div style="margin-bottom: 20px">
              <label for="abschliessenState">{{
                'formel.detail.verpackung.behaeltergroesse.einheit' | translate
              }}</label>
              <select
                id="abschliessenState"
                name="abschliessenState"
                class="form-control"
                [(ngModel)]="desiredAbschliessenState">
                <option></option>
                <option
                  *ngFor="let state of abschiessenStates"
                  value="{{ state }}">
                  {{ 'enum.state.' + state | translate }}
                </option>
              </select>
            </div>
            <button
              class="efo-button blue"
              (click)="abschliessen()"
              [disabled]="desiredAbschliessenState == undefined">
              {{ 'formel.detail.abschliessen.title' | translate }}
            </button>
          </app-bew-box>
        </div>

        <div *ngIf="showEinreichen()">
          <button
            class="efo-button blue"
            (click)="einreichen()"
            [disabled]="!contentMutable">
            {{ 'formel.detail.einreichen.title' | translate }}
          </button>
        </div>
        <div *ngFor="let error of errorsEinreichen" class="efo-error-message">
          {{ error | translate }}
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="archiveWarning"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="archiveWarningLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="archiveWarningLabel">
              {{ 'global.warning' | translate }}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ 'formel.archive.warning' | translate }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              (click)="archive()">
              {{ 'formel.archive.label' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal">
              {{ 'global.abort' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="archiveHint"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="archiveHintLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="archiveHintLabel">
              {{ 'global.hint' | translate }}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ 'formel.archive.status_hint' | translate }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #tooltipAllgemeinPreaparatName>
    <div
      [innerHTML]="
        'formel.detail.allgemein.preaparat.name.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipAllgemeinPreaparatArt>
    <div
      [innerHTML]="
        'formel.detail.allgemein.preaparat.art.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipAllgemeinIndikation>
    <div
      [innerHTML]="
        'formel.detail.allgemein.indikation.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipAllgemeinDosierungsvorschriften>
    <div
      [innerHTML]="
        'formel.detail.allgemein.dosierungsvorschriften.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipAllgemeinDarreichungsform>
    <div
      [innerHTML]="
        'formel.detail.allgemein.darreichungsform.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipAllgemeinLohnherstellung>
    <div
      [innerHTML]="
        'formel.detail.allgemein.lohnherstellung.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipZusammensetzungSortierreihenfolge>
    <div
      [innerHTML]="
        'formel.detail.zusammensetzung.sortierreihenfolge.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipZusammensetzungBezeichnung>
    <div
      [innerHTML]="
        'formel.detail.zusammensetzung.bezeichnung.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipZusammensetzungChargengroesse>
    <div
      [innerHTML]="
        'formel.detail.zusammensetzung.chargengroesse.tooltip' | translate
      "></div>
  </ng-template>

  <ng-template #tooltipZusammensetzungQualitaet>
    <div
      [innerHTML]="
        'formel.detail.zusammensetzung.qualitaet.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipZusammensetzungArt>
    <div
      [innerHTML]="
        'formel.detail.zusammensetzung.art.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipZusammensetzungEinheitsmenge>
    <div
      [innerHTML]="
        'formel.detail.zusammensetzung.einheitsmenge.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipLagerungLagerungshinweise>
    <div
      [innerHTML]="
        'formel.detail.lagerung.lagerungshinweise.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipLagerungHaltbarkeitsdauer>
    <div
      [innerHTML]="
        'formel.detail.lagerung.haltbarkeitsdauer.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipVerpackungBehaeltergroesse>
    <div
      [innerHTML]="
        'formel.detail.verpackung.behaeltergroesse.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipVerpackungBehaeltergroesseArt>
    <div
      [innerHTML]="
        'formel.detail.verpackung.behaeltergroesse.art.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipVerpackungPackungsinhalt>
    <div
      [innerHTML]="
        'formel.detail.verpackung.behaeltergroesse.packungsinhalt.tooltip'
          | translate
      "></div>
  </ng-template>
  <ng-template #tooltipVerpackungArt>
    <div
      [innerHTML]="
        'formel.detail.verpackung.behaeltergroesse.art.tooltip' | translate
      "></div>
  </ng-template>
  <ng-template #tooltipVerpackungEtiketten>
    <div
      [innerHTML]="
        'formel.detail.verpackung.etiketten.tooltip' | translate
      "></div>
  </ng-template>
</div>

<ng-template #tooltipFormelSave>
  <div [innerHTML]="'tooltip.formel_save' | translate"></div>
</ng-template>
<ng-template #tooltipFormelCopy>
  <div [innerHTML]="'tooltip.formel_copy' | translate"></div>
</ng-template>
<ng-template #tooltipFormelArchive>
  <div [innerHTML]="'tooltip.formel_archive' | translate"></div>
</ng-template>

<ng-template #tooltipEdit>
  <div [innerHTML]="'tooltip.edit' | translate"></div>
</ng-template>
<ng-template #tooltipDelete>
  <div [innerHTML]="'tooltip.delete' | translate"></div>
</ng-template>
<ng-template #tooltipTogglePruefungsnotes>
  <div [innerHTML]="'tooltip.toggle_pruefungsnotes' | translate"></div>
</ng-template>
