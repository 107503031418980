import { Injectable } from '@angular/core';
import { PersistedValue } from '../data/persisted-value';
import { first, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BerechtigungDTO } from '../backend/model/berechtigungDTO';
import { BerechtigungApiService, BetriebDTO } from '../backend';
import RolleEnum = BerechtigungDTO.RolleEnum;

@Injectable({
  providedIn: 'root',
})
export class BerechtigungService {
  private static readonly storageKeyForBerechtigung = 'berechtigung';
  private readonly berechtigungPersistedValue: PersistedValue<BerechtigungDTO | null>;

  constructor(private readonly berechtigungApiService: BerechtigungApiService) {
    this.berechtigungPersistedValue =
      new PersistedValue<BerechtigungDTO | null>(
        BerechtigungService.storageKeyForBerechtigung,
        () => {
          return null;
        },
        sessionStorage,
      );
  }

  get currentBerechtigung(): Observable<BerechtigungDTO | null> {
    return this.berechtigungPersistedValue.observable;
  }

  isCurrentBerechtigung(id: string) {
    return id == this.berechtigungPersistedValue.value?.id;
  }

  hasBerechetigung() {
    return this.berechtigungPersistedValue.value != undefined;
  }

  isAdminOrHasAnyRolle(rollen: RolleEnum[]): Observable<boolean> {
    rollen.push(RolleEnum.ADMINISTRATOR);
    return this.currentBerechtigung.pipe(
      map(
        (berechtigung) =>
          berechtigung != null &&
          berechtigung.rolle != null &&
          rollen.includes(berechtigung.rolle),
      ),
    );
  }

  berechtigungHasRolle(
    berechtigung: BerechtigungDTO | null,
    rollen: RolleEnum[],
  ): boolean {
    if (berechtigung && berechtigung.rolle) {
      return rollen.includes(berechtigung.rolle);
    } else {
      return false;
    }
  }

  isAdminOrHasAnyRolleAndBetrieb(rollen: RolleEnum[]): Observable<boolean> {
    rollen.push(RolleEnum.ADMINISTRATOR);
    return this.currentBerechtigung.pipe(
      map(
        (berechtigung) =>
          berechtigung != null &&
          berechtigung.betrieb != null &&
          berechtigung.rolle != null &&
          rollen.includes(berechtigung.rolle),
      ),
    );
  }

  get currentBetrieb(): Observable<BetriebDTO | undefined> {
    return this.currentBerechtigung.pipe(
      map((berechtigung) => berechtigung?.betrieb),
    );
  }

  isAdmin(): boolean {
    return (
      this.berechtigungPersistedValue.value?.rolle == RolleEnum.ADMINISTRATOR
    );
  }

  isPad(): boolean {
    const currentRolle = this.berechtigungPersistedValue.value?.rolle;
    return (
      currentRolle == RolleEnum.PADSEKRETARIAT ||
      currentRolle == RolleEnum.PADSACHBEARBEITER
    );
  }

  isAdminOrPad(): boolean {
    return this.isPad() || this.isAdmin();
  }

  isPadSachbearbeiter(): boolean {
    const currentRolle = this.berechtigungPersistedValue.value?.rolle;
    return currentRolle == RolleEnum.PADSACHBEARBEITER;
  }

  isPadSekretariat(): boolean {
    const currentRolle = this.berechtigungPersistedValue.value?.rolle;
    return currentRolle == RolleEnum.PADSEKRETARIAT;
  }

  isBetrieb(): boolean {
    const currentRolle = this.berechtigungPersistedValue.value?.rolle;
    return (
      currentRolle == RolleEnum.EFOBETRIEBSMITARBEITER ||
      currentRolle == RolleEnum.EFOBETRIEBSLEITER ||
      currentRolle == RolleEnum.EFOBETRIEBSLEITERSTV
    );
  }

  hasBetrieb(): boolean {
    return this.berechtigungPersistedValue.value?.betrieb != null;
  }

  currentBetriebOnce(): BetriebDTO | undefined {
    return this.berechtigungPersistedValue.value?.betrieb;
  }

  currentBetriebName(): string {
    const betriebName = this.berechtigungPersistedValue.value?.betrieb?.name;
    if (betriebName != undefined) {
      return betriebName;
    } else {
      return 'error.common.no.betrieb.selected';
    }
  }

  get hasBetriebSubscripe(): Observable<boolean> {
    return this.currentBerechtigung.pipe(
      map((berechtigung) => berechtigung?.betrieb !== null),
    );
  }

  get isBerechtigt(): boolean {
    return this.berechtigungPersistedValue.value !== null;
  }

  setBerechtigung(BerechtigungDTO: BerechtigungDTO) {
    this.berechtigungPersistedValue.value = BerechtigungDTO;
  }

  setBetrieb(betriebDTO: BetriebDTO) {
    this.currentBerechtigung.pipe(first()).subscribe((berechtigung) => {
      if (berechtigung) {
        berechtigung.betrieb = betriebDTO;
        this.setBerechtigung(berechtigung);
      }
    });
  }

  setKorrespondenzMail(mail: string) {
    this.currentBerechtigung.pipe(first()).subscribe((berechtigung) => {
      if (berechtigung && berechtigung.betrieb) {
        berechtigung.betrieb.korrespondenzMail = mail;
        this.setBerechtigung(berechtigung);
      }
    });
  }

  removeBerechtigungAndClearSessionStorage() {
    this.berechtigungPersistedValue.value = null;
    sessionStorage.clear();
  }

  selectFirstBerechtigung() {
    if (!this.hasBerechetigung()) {
      this.berechtigungApiService
        .findAllByCurrentBenutzer('rolle', 0, 100, 'DESC')
        .subscribe((page) => {
          if (page.content && page.content.length > 0) {
            this.setBerechtigung(page.content[0]);
          }
        });
    }
  }
}
